import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import {
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  Card,
  CardBody,
  CardFooter,
} from "reactstrap"
import { SHUK_AUTH_PORTAL_TOKEN } from "redux/enums"
import { registerAuthenticator } from "redux/slice/user/userSlice"
import { parseJwt } from "utilities/parse-jwt"
import ReactQRCode from "react-qr-code"

export default function UserSettings() {
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const [AuthUrl, setAuthUrl] = useState("")
  const appname = "ShukreIlahi"
  const handleSetupAuthenticator = () => {
    let data = {
      twoFactorType: "Authenticator",
    }
    dispatch(registerAuthenticator(data))
      .then(async res => {
        if (res.payload?.response?.status) {
          let token = localStorage.getItem(SHUK_AUTH_PORTAL_TOKEN)
          let decode = await parseJwt(token)
          let email =
            decode?.[
              "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
            ]
          console.log("decode[email]", email)
          if (res.payload?.response?.data && email) {
            setAuthUrl(
              `otpauth://totp/${appname}:${email}?secret=${res.payload?.response?.data}&issuer=${appname}`,
            )
            setModal(true)
          }
        } else {
          console.log("status not ok")
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="card-title fs-2 mt-2 mb-4 dot-after">Settings</div>
          <div className="row">
            <Link
              onClick={handleSetupAuthenticator}
              className="fs-4 text-primary-si"
            >
              Setup Authenticator App
            </Link>
            {/* <div className="col-12 d-flex justify-content-between align-items-center">
                <Form className="w-100">
                <FormGroup switch className="d-flex align-items-center w-100">
                    <Label check className="me-2" style={{ width: "calc(100% - 50px)", fontSize: '100%', width: "auto" }}>
                    Enable Two-Factor Authentication
                    </Label>    
                    <Input
                    type="switch"
                    role="switch"
                    style={{ width: '50px', height: '23px', marginRight: "5px" }}
                    />        
                </FormGroup>
                </Form>
            </div> */}
          </div>
        </div>
      </div>
      <Modal isOpen={modal} centered toggle={() => setModal(!modal)}>
        <Card className="card bg-white">
          <CardBody className="mt-2">
            <h3 style={{ fontWeight: "bold" }}>Setup Authenticator</h3>
            <p>Open the Authenticator app and scan the qr code</p>
            <div className="d-flex justify-content-center align-items-center">
              <ReactQRCode value={AuthUrl} size={200} />
            </div>
          </CardBody>
        </Card>
      </Modal>
    </div>
  )
}
