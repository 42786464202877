import { InputField, PhoneInputField, SelectField } from "components/Common/input-fields"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { IoMdAdd } from "react-icons/io"
import { MdCancel } from "react-icons/md"

export default function PersonalInfoForm({
  tabs,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  handleFileRemove,
  personalInfo,
  forView,
}) {
  let infoObject = "personal"
  const [dependentsState, setDependentsState] = useState(
    Array.isArray(personalInfo.householdDetails)
      ? personalInfo.householdDetails
      : [{ name: "", age: "", relationship: "", occupation: "", annualIncome: "" }],
  )

  useEffect(() => {
    console.log(personalInfo)
    if (Array.isArray(personalInfo.householdDetails)) {
      setDependentsState(personalInfo.householdDetails)
    }
  }, [personalInfo])

  const handleAddDependent = () => {
    setDependentsState([
      ...dependentsState,
      { name: "", age: "", relationship: "", occupation: "", annualIncome: "" },
    ])
  }

  const handleRemoveDependent = index => {
    const updatedSubjects = dependentsState.filter((_, i) => i !== index)
    setDependentsState(updatedSubjects)
    handleChangeInfo(infoObject, "householdDetails", updatedSubjects)
  }

  const handleDetailChange = (index, key, value) => {
    const updatedSubjects = dependentsState.map((subject, i) =>
      i === index ? { ...subject, [key]: value } : subject,
    )
    setDependentsState(updatedSubjects)
    handleChangeInfo(infoObject, "householdDetails", updatedSubjects)
  }

  return (
    <React.Fragment>
      <h5 className="mt-4 text-secondary-si">Personal Details</h5>

      <InputField
        label="First Name"
        compulsory={true}
        type="text"
        disabled={forView}
        value={personalInfo.firstName}
        placeholder="Type First Name"
        onChange={e =>
          handleChangeInfo(infoObject, "firstName", e.target.value)
        }
      />
      <InputField
        label="Middle Name"
        compulsory={false}
        type="text"
        disabled={forView}
        value={personalInfo.middleName}
        placeholder="Type Middle Name"
        onChange={e =>
          handleChangeInfo(infoObject, "middleName", e.target.value)
        }
      />
      <InputField
        label="Last Name"
        compulsory={true}
        type="text"
        disabled={forView}
        value={personalInfo.lastName}
        placeholder="Type Last Name"
        onChange={e => handleChangeInfo(infoObject, "lastName", e.target.value)}
      />
      <InputField
        label="Date of Birth"
        compulsory={true}
        type="date"
        disabled={forView}
        value={personalInfo.dateOfBirth}
        placeholder="Select Date of Birth"
        onChange={e =>
          handleChangeInfo(infoObject, "dateOfBirth", e.target.value)
        }
      />
      <InputField
        label="Email"
        compulsory={true}
        type="email"
        disabled={forView}
        value={personalInfo.email}
        placeholder="Type Email"
        onChange={e => handleChangeInfo(infoObject, "email", e.target.value)}
      />
      <PhoneInputField
        label="Contact Number"
        placeholder="Enter phone number"
        compulsory={true}
        disabled={forView}
        value={personalInfo.phoneNumber}
        onChange={value => handleChangeInfo(infoObject, "phoneNumber", value)}
      />
      {/* <PhoneInputField
        label="Alternate Contact Number"
        compulsory={false}
        disabled={forView}
        value={personalInfo.alternatePhoneNumber}
        placeholder="Type Alternate Contact"
        onChange={e => handleChangeInfo(infoObject, "alternatePhoneNumber", e)}
      /> */}
      {/* <InputField
        label="Father's First Name"
        compulsory={true}
        type="text"
        disabled={forView}
        value={personalInfo.fatherFirstName}
        placeholder="Type Father's First Name"
        onChange={e =>
          handleChangeInfo(infoObject, "fatherFirstName", e.target.value)
        }
      />
      <InputField
        label="Father's Middle Name"
        compulsory={false}
        type="text"
        disabled={forView}
        value={personalInfo.fatherMiddleName}
        placeholder="Type Father's Middle Name"
        onChange={e =>
          handleChangeInfo(infoObject, "fatherMiddleName", e.target.value)
        }
      />
      <InputField
        label="Father's Last Name"
        compulsory={true}
        type="text"
        disabled={forView}
        value={personalInfo.fatherLastName}
        placeholder="Type Father's Last Name"
        onChange={e =>
          handleChangeInfo(infoObject, "fatherLastName", e.target.value)
        }
      />
      <InputField
        label="Mother's First Name"
        compulsory={true}
        type="text"
        disabled={forView}
        value={personalInfo.motherFirstName}
        placeholder="Type Mother's First Name"
        onChange={e =>
          handleChangeInfo(infoObject, "motherFirstName", e.target.value)
        }
      />
      <InputField
        label="Mother's Middle Name"
        compulsory={false}
        type="text"
        disabled={forView}
        value={personalInfo.motherMiddleName}
        placeholder="Type Mother's Middle Name"
        onChange={e =>
          handleChangeInfo(infoObject, "motherMiddleName", e.target.value)
        }
      />
      <InputField
        label="Mother's Last Name"
        compulsory={true}
        type="text"
        disabled={forView}
        value={personalInfo.motherLastName}
        placeholder="Type Mother's Last Name"
        onChange={e =>
          handleChangeInfo(infoObject, "motherLastName", e.target.value)
        }
      />
      <InputField
        label="Alternate Address"
        compulsory={false}
        type="text"
        disabled={forView}
        value={personalInfo.alternateAddress}
        placeholder="Type Alternate Address"
        onChange={e => handleChangeInfo(infoObject, "alternateAddress", e.target.value)}
      />
      <InputField
        label="PIN Code"
        compulsory={true}
        type="text"
        disabled={forView}
        value={personalInfo.postalCode}
        placeholder="Type PIN Code"
        onChange={e => handleChangeInfo(infoObject, "postalCode", e.target.value)}
      />
      <InputField
        label="Country"
        compulsory={true}
        type="text"
        disabled={forView}
        value={personalInfo.country}
        placeholder="Type Country"
        onChange={e => handleChangeInfo(infoObject, "country", e.target.value)}
      />
      <InputField
        label="State"
        compulsory={true}
        type="text"
        disabled={forView}
        value={personalInfo.state}
        placeholder="Type State"
        onChange={e => handleChangeInfo(infoObject, "state", e.target.value)}
      />
      <InputField
        label="City"
        compulsory={true}
        type="text"
        disabled={forView}
        value={personalInfo.city}
        placeholder="Type City"
        onChange={e => handleChangeInfo(infoObject, "city", e.target.value)}
      />
      <InputField
        label="Father's Occupation"
        compulsory={true}
        type="text"
        disabled={forView}
        value={personalInfo.fatherOccupation}
        placeholder="Type Father's Occupation"
        onChange={e =>
          handleChangeInfo(infoObject, "fatherOccupation", e.target.value)
        }
      />
      <InputField
        label="Mother's Occupation"
        compulsory={true}
        type="text"
        disabled={forView}
        value={personalInfo.motherOccupation}
        placeholder="Type Mother's Occupation"
        onChange={e =>
          handleChangeInfo(infoObject, "motherOccupation", e.target.value)
        }
      /> */}
      <InputField
        label="Total Household Members"
        compulsory={true}
        type="number"
        disabled={forView}
        value={personalInfo.totalHouseholdMembers}
        placeholder="Enter Total Household Members"
        onChange={e =>
          handleChangeInfo(
            infoObject,
            "totalHouseholdMembers",
            e.target.value,
          )
        }
      />
      <SelectField
        label="House Status"
        compulsory={true}
        selectOptions={["Rented", "Owned"]}
        type="text"
        disabled={forView}
        value={personalInfo.houseStatus}
        placeholder="Enter House Status"
        onChange={e =>
          handleChangeInfo(
            infoObject,
            "houseStatus",
            e.target.value,
          )
        }
      />
      <InputField
        label="Home Address"
        compulsory={true}
        type="text"
        disabled={forView}
        value={personalInfo.homeAddress}
        placeholder="Type Home Address"
        onChange={e => handleChangeInfo(infoObject, "homeAddress", e.target.value)}
      />
      <InputField
        label="Annual Income"
        compulsory={true}
        type="text"
        disabled={forView}
        value={personalInfo.annualIncome}
        placeholder="Type Annual Income"
        onChange={e => handleChangeInfo(infoObject, "annualIncome", e.target.value)}
      />
      <InputField
        label="Annual Income Currency"
        compulsory={true}
        type="text"
        disabled={forView}
        value={personalInfo.incomeCurrency}
        placeholder="Type Annual Income Currency"
        onChange={e => handleChangeInfo(infoObject, "incomeCurrency", e.target.value)}
      />
      {dependentsState.map((dependent, index) => (
        <div key={index} className="input-fields-group">
          <p className="fs-6 text-secondary-si">Dependent {index + 1}</p>
          <div className="row">
            <InputField
              label="Name"
              type="text"
              className="col-sm-12 col-md-6 col-lg-4 my-2"
              compulsory={true}
              value={dependent.name}
              placeholder="Enter Name"
              disabled={forView}
              onChange={e => handleDetailChange(index, "name", e.target.value)}
            />
            <InputField
              label="Age"
              type="text"
              className="col-sm-12 col-md-6 col-lg-4 my-2"
              value={dependent.age}
              placeholder="Enter Age"
              disabled={forView}
              onChange={e => handleDetailChange(index, "age", e.target.value)}
            />
            <InputField
              label="Relationship"
              type="text"
              className="col-sm-12 col-md-6 col-lg-4 my-2"
              compulsory={true}
              value={dependent.relationship}
              placeholder="Enter Relationship"
              disabled={forView}
              onChange={e =>
                handleDetailChange(index, "relationship", e.target.value)
              }
            />
            <InputField
              label="Occupation"
              type="text"
              className="col-sm-12 col-md-6 col-lg-4 my-2"
              compulsory={true}
              value={dependent.occupation}
              placeholder="Enter Occupation"
              disabled={forView}
              onChange={e =>
                handleDetailChange(index, "occupation", e.target.value)
              }
            />
            <InputField
              label="Annual Income"
              type="number"
              className="col-sm-12 col-md-6 col-lg-4 my-2"
              compulsory={true}
              value={dependent.annualIncome}
              placeholder="Enter Anuual Income"
              disabled={forView}
              onChange={e =>
                handleDetailChange(index, "annualIncome", e.target.value)
              }
            />
            {!forView && (
              <div className="col col-sm-12 col-md-4 col-lg-4 mb-2 d-flex align-items-end">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleRemoveDependent(index)}
                >
                  <MdCancel />
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
      {!forView ? (
        <button
          type="button"
          className="btn btn-success my-2 px-4 float-right"
          onClick={handleAddDependent}
        >
          <IoMdAdd /> Add Dependent
        </button>
      ) : null}
    </React.Fragment>
  )
}
