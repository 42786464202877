import React, { useContext } from "react"
import { Card, CardBody, Row, Col } from "reactstrap"
import style from "./dashboard.module.scss"
import { useNavigate } from "react-router-dom"
import SidebarContext from "context/sidebar.context"

const Miniwidget = props => {
  let navigate = useNavigate()
  const { applicationFilters, setApplicationFilters, setActiveStates } = useContext(SidebarContext)

  const handleStatusWidget = (status) => {
    setApplicationFilters((prev)=>({
      ...prev,
      status: status
    }))
    setActiveStates({
      main: "Application List",
      child: "",
    })
    navigate("/applications")
  }

  return (
    <React.Fragment>
      <Row>
        {props.reports.map((report, key) => (
          <Col xl={4} sm={6} key={key}>
            <Card className="mini-stat" style={{backgroundColor: "var(--theme-color-secondary)"}}>
              <CardBody className="card-body mini-stat-img" style={{cursor: "pointer"}} onClick={()=>{handleStatusWidget(report.statusId)}}>
                <div
                  className={`mini-stat-icon position-absolute ${style.miniWidgetIcon}`}
                >
                  {/* <i className={"float-end mdi mdi-" + report.iconClass}></i> */}
                  <i>{report.icon}</i>
                </div>
                <div className="text-white">
                  <h6 className="text-uppercase mb-3 font-size-16 text-white">
                    {report.title}
                  </h6>
                  <h2 className="mb-4 text-white">{report.total}</h2>
                  <span className={"badge bg-" + report.badgecolor}>
                    {" "}
                    {Math.floor(report.average)}{" %"}
                  </span>{" "}
                  <span className="ms-2"></span>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  )
}

export default Miniwidget
