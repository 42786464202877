import React, { useEffect, useRef, useState } from "react"
import SimpleBar from "simplebar-react/dist"
import user2 from "../../assets/images/users/user-2.jpg"
import {
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Button,
  Input,
  UncontrolledAlert,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import Spinners from "components/Common/Spinner"
import {
  allChatsData,
  ChatMessagesFetch,
  ChatMessagesFetchAllStaff,
  SendMessage,
  SetCurrentStaff,
  UpdateChatRead,
  UpdateChattingPerson,
} from "redux/slice/chats/chatSlice"
import { DateFormater, DateTimeFormatter } from "utilities/dateformater"
import { IoCheckmarkDoneSharp } from "react-icons/io5"
import Loader from "components/Common/loader"
import { SHUK_AUTH_PORTAL_TOKEN } from "redux/enums"
import { getUserTokenID, parseJwt } from "utilities/parse-jwt"
import { allApplications, SelectingCurrentApplication } from "redux/slice/applications/applicationSlice"

const SideChat = props => {
  const dispatch = useDispatch()
  const {
    chatsUsersList,
    chatRoomMessages,
    staffId,
    loadingChats,
    loadingMessages,
    openChatRoom,
  } = useSelector(allChatsData)
  const { currentApplicationSelected } = useSelector(allApplications)
  const [activeTab, setactiveTab] = useState("1")
  const [curMessage, setcurMessage] = useState("")
  const [searchedText, setSearchedText] = useState("")
  const [searchedUsers, setSearchedUsers] = useState([])
  const [isUserListVisible, setIsUserListVisible] = useState(true)
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const [currentStaff, setCurrentStaff] = useState("")

  const fetchRoomMessages = async std => {
    // const token = localStorage.getItem(SHUK_AUTH_PORTAL_TOKEN)
    // let parsedToken = await parseJwt(token)
    // let _staffId =
    //   parsedToken?.[
    //     "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
    //   ]
    // dispatch(UpdateChattingPerson(std))
    let data = {
      studentId: std.studentId,
      // staffId: staffId || _staffId,
    }
    dispatch(ChatMessagesFetchAllStaff(data))
    // dispatch(ChatMessagesFetch(data))
      // .then(res => {
      //   // console.log("resultForMSgRes", res?.payload?.data?.items)
      //   if (
      //     res?.payload?.data?.items?.chats &&
      //     res?.payload?.data?.items?.chats?.length > 0
      //   ) {
      //     let lastReadMessage = {
      //       ...data,
      //       chatId: res?.payload?.data?.items?.chatId,
      //       //chatID need to add
      //     }
      //     dispatch(UpdateChatRead(lastReadMessage))
      //   }
      // })
      // .catch(error => {
      //   console.log("error", error)
      // })
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  // useEffect(()=>{
  //   console.log("curMessage changes", curMessage)
  // }, [curMessage])

  const currentTime = new Date().toISOString()
  // const hours = currentTime.getHours()
  // const minutes = currentTime.getMinutes()
  // const time = `${hours}: ${minutes}`
  const sendNewMessage = async () => {
    try {
      // console.log("currentTime", currentTime)
      const token = localStorage.getItem(SHUK_AUTH_PORTAL_TOKEN)
      let parsedToken = await parseJwt(token)
      let _staffId =
        parsedToken?.[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
        ]
      if (curMessage !== "") {
        let data = {
          studentId: currentApplicationSelected?.id,
          staffId: staffId || _staffId,
          communicationJson: {
            userId: staffId || _staffId,
            message: curMessage,
            time: currentTime,
            isRead: false,
          },
        }
        // console.log("message data", data)
        dispatch(SendMessage(data))
          .then(result => {
            // console.log("result of send api", result)
            if (result?.payload?.status === 200) {
              setcurMessage("")
              let refreshMsgData = {
                studentId: data?.studentId,
                // staffId: staffId || _staffId,
              }
              dispatch(ChatMessagesFetchAllStaff(refreshMsgData))
            }
          })
          .catch(err => {
            console.log("message sending error", err)
          })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onKeyPress = e => {
    const { key, value } = e
    if (key === "Enter") {
      setcurMessage(value)
      // setDisable(true)
      sendNewMessage()
    }
  }

  //serach users
  const searchUsers = () => {
    try {
      // console.log("search text", searchedText)
      if (searchedText !== "") {
        let searchedData = chatsUsersList.filter(item =>
          item?.studentName?.toLowerCase().includes(searchedText.toLowerCase()),
        )
        setSearchedUsers(searchedData)
      } else {
        setSearchedUsers(chatsUsersList)
      }
    } catch (err) {
      console.log("error", err)
    }
  }

  useEffect(() => {
    searchUsers()
  }, [searchedText, chatsUsersList])

  const [deleteMsg, setDeleteMsg] = useState("")
  const toggle_deleMsg = id => {
    setDeleteMsg(!deleteMsg)
    // dispatch(onDeleteMessage(id))
  }

  const [copyMsgAlert, setCopyMsgAlert] = useState(false)
  const copyMsg = ele => {
    var copyText = ele.closest(".message-list").querySelector("p").innerHTML
    navigator.clipboard.writeText(copyText)
    setCopyMsgAlert(true)
    if (copyText) {
      setTimeout(() => {
        setCopyMsgAlert(false)
      }, 1000)
    }
  }

  // scroll simple bar
  const scrollRef = useRef(null)
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [props?.tabSwitch, chatRoomMessages])

  // const findingCurrentUser = async () => {
  //   let currentUserId = await getUserTokenID() 
  //   dispatch(SetCurrentStaff(currentUserId))
  // }

  useEffect(() => {
    // findingCurrentUser();
    let refreshTimeout;
    if (currentApplicationSelected && currentApplicationSelected?.id) {
      let std = {
        studentId: currentApplicationSelected?.id,
      }
      fetchRoomMessages(std)
      refreshTimeout = setInterval(()=>{
        console.log("refreshing messages")
        fetchRoomMessages(std)
      }, 90000);
    } else {
      if (refreshTimeout) {
        console.log("clearing interval>>>")
        clearInterval(refreshTimeout)
      }
    }
    return () => {
      if (refreshTimeout) {
        console.log("interval ending...")
        dispatch(SelectingCurrentApplication({}))
        clearInterval(refreshTimeout)
      }
    }
  }, [currentApplicationSelected?.id])

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window?.innerWidth <= 768)
      if (window?.innerWidth > 768) {
        setIsUserListVisible(true)
      }
    }

    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <React.Fragment>
      <div className="side-chat-ui" style={{ maxHeight: "200px !important" }}>
        <div className="chat-box">
          <div className="side-chat-message-box" ref={scrollRef}>
            
              <ul className="list-unstyled mb-0">
                {chatRoomMessages &&
                  chatRoomMessages?.length > 0 &&
                  [...chatRoomMessages]?.reverse().map((userMsg, index) => {
                    return (
                      <li
                        key={index}
                        className={userMsg?.userId !== staffId ? "" : "right"}
                      >
                        <div className="message-list">
                          <div className="d-flex">
                            {userMsg?.images && (
                              <img
                                src={userMsg?.images}
                                className="rounded-circle avatar"
                                alt=""
                              />
                            )}
                            <div className="flex-1 ms-3">
                              {/* <div className="d-flex justify-content-between">
                              </div> */}
                              <div className="ctext-wrap">
                                {/* for seperate other staff colors add this line  ${userMsg?.userId !== currentApplicationSelected?.id && userMsg?.userId !== staffId ? "other-users-msg" : ""} */}
                                <div className={`ctext-wrap-content`}>
                                  {/* <p className="fw-bold text-secondary-si">{userMsg?.userName}</p> */}
                                  {chatRoomMessages &&
                                  userMsg?.userId === currentApplicationSelected?.id ? (
                                    <p className="fw-bold text-secondary-si">
                                      {currentApplicationSelected?.firstName + " "}{currentApplicationSelected?.middleName ? currentApplicationSelected?.middleName + " " : ""}{currentApplicationSelected?.lastName ? currentApplicationSelected?.lastName + " " : ""}
                                    </p>
                                  ) : <p className="fw-bold text-secondary-si">
                                        {userMsg?.userName ? userMsg.userName : ""}
                                      </p>}
                                  <p
                                    className="mb-0"
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {userMsg?.message}
                                  </p>
                                  {userMsg?.time !== 0 && (
                                    <span className="msg-time fw-normal ">
                                      {userMsg?.time
                                        ? DateTimeFormatter(userMsg?.time)
                                        : ""}{" "}
                                      {userMsg?.userId === staffId && (
                                        <IoCheckmarkDoneSharp
                                          size={20}
                                          color={userMsg?.isRead ? "blue" : ""}
                                        />
                                      )}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    )
                    // })
                  })}
              </ul>

          </div>

          <div className="p-3 border-top bg-light">
            <div className="row">
              <div className="col-12">
                <div className="position-relative">
                  <textarea
                    type="text"
                    row={10}
                    value={curMessage}
                    // onKeyPress={onKeyPress}
                    onChange={e => {
                      setcurMessage(e.target.value)
                      // setDisable(true)
                    }}
                    // onKeyDown={e => {
                    //   if (e.key === "Enter") {
                    //     sendNewMessage()
                    //   }
                    // }}
                    className="form-control border side-chat-input"
                    placeholder="Enter Message..."
                  />
                </div>
              </div>
              <div className="col-auto d-flex justify-content-center align-items-end mb-1 mt-2">
                <Button
                  type="button"
                  // color="primary"
                  // disabled={!isdisable}
                  disabled={!curMessage}
                  onClick={sendNewMessage}
                  className="chat-send waves-effect waves-light"
                  style={{width: "100px"}}
                >
                  <span className="d-sm-inline-block me-2">Send</span>{" "}
                  <i className="mdi mdi-send float-end"></i>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SideChat
