export function DateFormater(dateString) {
    const date = new Date(dateString);
    const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    };

    const formatter = new Intl.DateTimeFormat("en-CA", options);
    return formatter.format(date);
}

export function DateTimeFormatter(dateString) {
  try {
    const date = new Date(dateString)

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }

    const formatter = new Intl.DateTimeFormat("en-CA", options)

    return formatter.format(date)
  } catch (error) {
    console.error("Error formatting date:", error.message)
    return "Invalid Date"
  }
}

export function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const today = new Date();

    let age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();
    const dayDifference = today.getDate() - dob.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }

    return age;
}