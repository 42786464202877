import {
  InputField,
  PasswordInputField,
  PhoneInputField,
  SelectFieldForId,
} from "components/Common/input-fields"
import Notify from "components/Common/notify"
import { useEffect, useState } from "react"
import Flatpickr from "react-flatpickr"
import { useSelector, useDispatch } from "react-redux"
import { allStaffs, ProfileDataFetch, StaffCreate, StaffUpdate } from "redux/slice/staff/staffSlice"
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js"
import user1 from "../../assets/images/users/user-3.jpg"
import { MdEdit } from "react-icons/md"
import { getUserTokenID } from "utilities/parse-jwt"
import Loader from "components/Common/loader"

export default function UserProfile(props) {
  const dispatch = useDispatch()
  const { profileData, loading } = useSelector(allStaffs)
  // let passwordRegex =
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>_\-+*/\[\]\(\)])[\S]{6,}$/
  // let mobileRegex = /^\+\d{12}$/
  // let emailRegex =
  //   /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/
  const [error, setError] = useState("")
  const [forView, setForView] = useState(true)
  const [createData, setCreateData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    role: "",
    email: "",
    userName: "",
    phoneNumber: "+1",
    // dateOfBirth: "",
    password: "",
    confirmPassword: "",
  })

  const fetchProfile = async () => {
    let id = await getUserTokenID()
    console.log("userId for profile", id)
    dispatch(ProfileDataFetch(id))
  }

  useEffect(() => {
    fetchProfile()
  }, [])

  useEffect(()=>{
    if (profileData) {
      setCreateData((prevData) => ({
        ...prevData,
        firstName: profileData?.firstName || "",
        middleName: profileData?.middleName || "",
        lastName: profileData?.lastName || "",
        email: profileData?.email || "",
        phoneNumber: profileData?.phoneNumber || "+1",
        role: profileData?.roles?.[0] || "",
      }));
    }
  }, [profileData])

  const handleChangeInfo = (fieldName, value) => {
    console.log(fieldName, "change to", value)
    setCreateData(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  }

  const handleSubmit = () => {}

  return (
    <div>
      {
        loading ? 
        <Loader /> : 
        <>
          <div className="row">
            <div className="col col-sm-12">
              {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}
              {/* {success ? <Alert color="success">{success}</Alert> : null} */}

              <div className="card bg-success-pri">
                <div className="card-body">
                  <div className="d-flex background-secondary-si">
                    {/* <div className="ms-3">
                      <img
                        src={user1}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div> */}
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted mx-2">
                        <h4 className="m-0 text-light">{createData?.firstName ? createData.firstName + " " : ""}{createData?.middleName ? createData.middleName + " " : ""}{createData?.lastName ? createData.lastName + " " : ""}</h4>
                        {/* <p className="mb-0 text-light">fahad@braincrop.io</p> */}
                        <p className="mb-0 text-light">{createData?.role}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card p-2">
            <div className="card-title fs-3 mt-2 mx-4">Profile Details</div>
            <div style={{ position: "absolute", top: "20px", right: "20px" }}>
              {/* {forView ? (
                <MdEdit onClick={e => setForView(!forView)} size={20} color="red" />
              ) : (
                null
                // <button
                //   className="btn btn-sm btn-danger"
                //   onClick={e => setForView(!forView)}
                // >
                //   Cancel
                // </button>
              )} */}
            </div>
            <div className="card-body">
              <div className="row">
                <InputField
                  label="First Name"
                  placeholder="Enter First Name"
                  className="col-sm-12 col-md-6 col-lg-4 my-2"
                  compulsory={true}
                  disabled={forView}
                  type="text"
                  value={createData.firstName}
                  onChange={e => handleChangeInfo("firstName", e.target.value)}
                />
                <InputField
                  label="Middle Name"
                  placeholder="Enter Middle Name"
                  className="col-sm-12 col-md-6 col-lg-4 my-2"
                  compulsory={false}
                  disabled={forView}
                  type="text"
                  value={createData.middleName}
                  onChange={e => handleChangeInfo("middleName", e.target.value)}
                />
                <InputField
                  label="Last Name"
                  placeholder="Enter Last Name"
                  className="col-sm-12 col-md-6 col-lg-4 my-2"
                  compulsory={false}
                  disabled={forView}
                  type="text"
                  value={createData.lastName}
                  onChange={e => handleChangeInfo("lastName", e.target.value)}
                />
                <SelectFieldForId
                  label="User Type"
                  className="col-sm-12 col-md-6 col-lg-4 my-2"
                  compulsory={true}
                  disabled={forView}
                  selectOptions={[
                    { id: "Admin", name: "Admin" },
                    { id: "Review Committee", name: "Review Committee" },
                    { id: "Board Member", name: "Board Member" },
                  ]}
                  value={createData.role}
                  onChange={e => handleChangeInfo("role", e.target.value)}
                />
                <InputField
                  label="Email"
                  placeholder="Enter Email"
                  className="col-sm-12 col-md-6 col-lg-4 my-2"
                  compulsory={true}
                  disabled={forView}
                  type="email"
                  value={createData.email}
                  onChange={e => {
                    handleChangeInfo("email", e.target.value)
                    handleChangeInfo("userName", e.target.value)
                  }}
                />
                <PhoneInputField
                  label="Phone Number"
                  placeholder="Enter phone number"
                  className="col-sm-12 col-md-6 col-lg-4 my-2"
                  compulsory={true}
                  disabled={forView}
                  value={createData.phoneNumber}
                  onChange={value => handleChangeInfo("phoneNumber", value)}
                />
                {/* <InputField
                  label="Date of Birth"
                  placeholder="Select Date of Birth"
                  className="col-sm-12 col-md-6 col-lg-4 my-2"
                  compulsory={true}
                  disabled={forView}
                  type="date"
                  value={createData.dateOfBirth}
                  onChange={e => handleChangeInfo("dateOfBirth", e.target.value)}
                /> */}
                {
                  !forView && (
                    <>
                      <PasswordInputField
                        label="Password"
                        placeholder="Enter Password"
                        className="col-sm-12 col-md-6 col-lg-4 my-2"
                        compulsory={true}
                        disabled={forView}
                        value={createData.password}
                        onChange={e => handleChangeInfo("password", e.target.value)}
                      />
                      <PasswordInputField
                        label="Confirm Password"
                        placeholder="Enter Confirm Password"
                        className="col-sm-12 col-md-6 col-lg-4 my-2"
                        compulsory={true}
                        disabled={forView}
                        value={createData.confirmPassword}
                        onChange={e =>
                          handleChangeInfo("confirmPassword", e.target.value)
                        }
                      />
                    </>
                  )
                }
              </div>
              {!forView ? (
                <div className="d-flex justify-content-end mt-4">
                  <button
                    className="btn btn-danger me-2"
                    onClick={() => setForView(!forView)}
                  >
                    Cancel
                  </button>
                  <button className="btn btn-primary" onClick={handleSubmit}>
                    Update
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </>
      }
    </div>
  )
}