import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Form,
  Alert,
  Input,
  FormFeedback,
} from "reactstrap"
import logoDark from "../../assets/images/Shukre_Ilahi_2.png"
import logoLight from "../../assets/images/Shukre_Ilahi_2.png"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"
import {
  LoginUser,
  verifyAuthenticatorCode,
  setApiResponseStutusToDefault,
  userData,
  sendAuthenticatorCode,
} from "redux/slice/user/userSlice"
import { SHUK_AUTH_PORTAL_TOKEN } from "redux/enums"
import { IoMdArrowRoundBack } from "react-icons/io"
import { AiOutlineMail } from "react-icons/ai"
import { SiGoogleauthenticator } from "react-icons/si"

const Login = props => {
  document.title = "Login | Shukre Ilahi"
  const { apiStatus, response, respFrom, error, loading } =
    useSelector(userData)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showAuthenticatorCode, setShowAuthenticatorCode] = useState(false)
  const [stage, setStage] = useState(1)
  const [authMechanism, setAuthMechanism] = useState("Email")
  const [resendEmail, setResendEmail] = useState("")

  // useEffect(() => {
  //   if (apiStatus && !error && respFrom === "Login") {
  //     setStage(2)
  //   }
  // }, [apiStatus])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
      authcode: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      if (stage === 3) {
        // Stage 3: Verify code
        dispatch(
          verifyAuthenticatorCode({
            email: values.email,
            twoFactorCode: values.authcode,
            twoFactorType: authMechanism,
          }),
        )
          .then(result => {
            if (result?.payload?.response?.status === 200) {
              dispatch(setApiResponseStutusToDefault())
              navigate("/dashboard")
            } else {
              console.log("Invalid authentication code.")
            }
          })
          .catch(error => {
            console.log("error sending code", error)
          })
      } else if (stage === 2) {
        // stage 2 choose authentication method
        if (authMechanism === "Email") {
          console.log("sending code to email")
          dispatch(sendAuthenticatorCode(values.email))
        } else if (authMechanism === "Authenticator") {
          console.log("use google code.")
        }
        setStage(3)
      } else if (stage === 1) {
        // Stage 1: Login with email and password
        setResendEmail(values.email)
        const data = { userName: values.email, password: values.password }
        dispatch(setApiResponseStutusToDefault())
        dispatch(LoginUser(data))
          .then(result => {
            console.log("response", result)
            if (result?.payload?.response?.status === 200) {
              dispatch(setApiResponseStutusToDefault())
              navigate("/dashboard")
            } else if(result?.payload?.status === 401){
              setStage(2)
            } else {
              console.log("Error sending code.")
            }
          })
          .catch(err => {
            console.log("Login error", err)
          })
      }
    },
  })

  const handleResend = () => {
    dispatch(sendAuthenticatorCode(resendEmail))
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        src={logoDark}
                        alt=""
                        height="230"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logoLight}
                        alt=""
                        height="230"
                        className="auth-logo-light"
                      />
                    </Link>
                  </h3>

                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Welcome Back!
                    </h4>
                    <p className="text-muted text-center">
                      Sign in to continue to Shukre Ilahi.
                    </p>
                    {apiStatus && error && respFrom === "Login" ? (
                      <Alert color={"danger"}>{response}</Alert>
                    ) : apiStatus && !error && respFrom === "Login" ? (
                      <Alert color={"success"}>{response}</Alert>
                    ) : null}

                    {/* for back button */}
                    {stage > 1 && (
                      <button
                        type="button"
                        className="btn"
                        onClick={() => setStage(stage - 1)}
                      >
                        <IoMdArrowRoundBack size={20} /> Back
                      </button>
                    )}

                    {/* form stages */}
                    {stage === 1 && (
                      <Form
                        className="form-horizontal mt-4"
                        onSubmit={validation.handleSubmit}
                      >
                        <div className="mb-3">
                          <Label htmlFor="username">Email</Label>
                          <Input
                            name="email"
                            type="email"
                            placeholder="Enter email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email}
                          />
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="userpassword">Password</Label>
                          <Input
                            name="password"
                            type="password"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password}
                          />
                        </div>

                        <Row className="mb-3 mt-4">
                          <Col xs={6}>
                          <div className="">
                              <Link className="text-danger w-100" to={"/forgot-password"}>Forgot Password?</Link>
                            </div>
                          </Col>
                          <Col xs={6} className="text-end">
                            <button
                              className="btn btn-success w-md waves-effect waves-light"
                              type="submit"
                              disabled={
                                loading ||
                                !validation.values.email ||
                                !validation.values.password
                              }
                            >
                              {loading ? (
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                  style={{ width: "1.1rem", height: "1.1rem" }}
                                />
                              ) : (
                                "Log In"
                              )}
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    )}

                    {stage === 2 && (
                      <Form
                        className="form-horizontal mt-4"
                        onSubmit={validation.handleSubmit}
                      >
                        <h5>Select Authentication Method</h5>
                        <Row className="mb-3">
                          <Col xs={12}>
                            <div
                              class="card mb-3"
                              style={{
                                maxWidth: "540px",
                                border: `${authMechanism == "Email" ? "1px solid var(--theme-color-secondary)" : "1px solid transparent"}`,
                                boxShadow: `${authMechanism == "Email" ? "0 0 0 0.25rem var(--theme-color-secondary-shadow)" : "0 0 0 0.25rem transparent"}`
                              }}
                              onClick={() => setAuthMechanism("Email")}
                            >
                              <div class="row g-0">
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                  <AiOutlineMail size={50} />
                                </div>
                                <div class="col-8">
                                  <div class="card-body">
                                    <h5 class="card-title">Email</h5>
                                    <p class="card-text">
                                      The code will be send to your email
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="card mb-3"
                              style={{
                                maxWidth: "540px",
                                border: `${authMechanism == "Authenticator" ? "1px solid var(--theme-color-secondary)" : "1px solid transparent"}`,
                                boxShadow: `${authMechanism == "Authenticator" ? "0 0 0 0.25rem var(--theme-color-secondary-shadow)" : "0 0 0 0.25rem transparent"}`
                              }}
                              onClick={() => setAuthMechanism("Authenticator")}
                            >
                              <div class="row g-0">
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                  <SiGoogleauthenticator size={50} />
                                </div>
                                <div class="col-8">
                                  <div class="card-body">
                                    <h5 class="card-title">
                                      Authenticator
                                    </h5>
                                    <p class="card-text">
                                      Use authenticator app code to authorize
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row className="mb-3 mt-4">
                          <Col xs={12} className="text-end">
                            <button
                              className="btn btn-success w-md waves-effect waves-light"
                              type="submit"
                              disabled={!authMechanism}
                            >
                              Send Code
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    )}

                    {stage === 3 && (
                      <Form
                        className="form-horizontal mt-4"
                        onSubmit={validation.handleSubmit}
                      >
                        <div className="mb-3">
                          <Label htmlFor="authcode">
                            Enter Verification Code
                          </Label>
                          <Input
                            name="authcode"
                            type="text"
                            placeholder="Enter verification code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.authcode}
                          />
                          {validation.errors.authcode &&
                          validation.touched.authcode ? (
                            <FormFeedback>
                              {validation.errors.authcode}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <Row className="mb-3 mt-4">
                          <Col xs={6}>
                            {authMechanism == "Email" ? (
                              <Link
                                className="text-secondary"
                                onClick={handleResend}
                              >
                                Resend code
                              </Link>
                            ) : null}
                          </Col>
                          <Col xs={6} className="text-end">
                            <button
                              className="btn btn-success w-md waves-effect waves-light"
                              type="submit"
                              disabled={loading || !validation.values.authcode}
                            >
                              {loading ? (
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                  style={{ width: "1.1rem", height: "1.1rem" }}
                                />
                              ) : (
                                "Verify Code"
                              )}
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                {/* <p>
                  Don't have an account?{" "}
                  <Link to="/register" className="text-primary">
                    Signup Now
                  </Link>
                </p> */}
                © {new Date().getFullYear()} Shukre Ilahi - Developed by Happy
                Digital.
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)
