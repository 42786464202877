import React, { useEffect, useState } from "react"
import { InputField, InputImageField, SelectField } from "components/Common/input-fields"
import { IoMdAdd } from "react-icons/io"
import { MdCancel } from "react-icons/md"

export default function ReferenceInfoForm({
  handleChangeInfo,
  community,
  forView,
}) {
  let infoObject = "refernce"
  const [refferenceDetailsState, setRefferenceDetailsState] = useState(
    Array.isArray(community.refernceDetails)
      ? community.refernceDetails
      : [
          {
            name: "",
            email: "",
            phoneNumber: "",
            relationshipWithApplicant: "",
            coverLetterImageUrl: "",
          }
        ],
  )

  useEffect(() => {
    if (Array.isArray(community.refernceDetails)) {
      console.log("community.refernceDetails", community)
      setRefferenceDetailsState(community.refernceDetails)
    }
  }, [community])

  const handleAddReffernece = () => {
    setRefferenceDetailsState([
      ...refferenceDetailsState,
      {
        name: "",
        email: "",
        phoneNumber: "",
        relationshipWithApplicant: "",
        coverLetterImageUrl: "",
      },
    ])
  }

  const handleRemoveRefference = index => {
    const updatedSubjects = refferenceDetailsState.filter((_, i) => i !== index)
    setRefferenceDetailsState(updatedSubjects)
    handleChangeInfo(infoObject, "refernceDetails", updatedSubjects)
  }

  const handleDetailChange = (index, key, value) => {
    const updatedSubjects = refferenceDetailsState.map((subject, i) =>
      i === index ? { ...subject, [key]: value } : subject,
    )
    setRefferenceDetailsState(updatedSubjects)
    handleChangeInfo(infoObject, "refernceDetails", updatedSubjects)
  }

  return (
    <React.Fragment>
      <h5 className="mt-4 text-secondary-si section-heading page-break">Reference Details</h5>

      {refferenceDetailsState.map((refferPerson, index) => (
        <div key={index} className="input-fields-group">
          <p className="fs-6 text-secondary-si mt-2">Reference Detail {index + 1}</p>
          <div className="row" key={index}>
            <InputField
              label="Name"
              type="text"
              compulsory={true}
              value={refferPerson.name}
              placeholder="Enter Name"
              disabled={forView}
              onChange={e =>
                handleDetailChange(index, "name", e.target.value)
              }
            />
            <InputField
              label="Email"
              type="email"
              value={refferPerson.email}
              placeholder="Enter Email"
              disabled={forView}
              onChange={e =>
                handleDetailChange(index, "email", e.target.value)
              }
            />
            <InputField
              label="Phone Number"
              type="text"
              compulsory={true}
              value={refferPerson.phoneNumber}
              placeholder="Enter Phone Number"
              disabled={forView}
              onChange={e =>
                handleDetailChange(index, "phoneNumber", e.target.value)
              }
            />
            <InputField
              label="Relationship with Applicants"
              type="text"
              compulsory={true}
              value={refferPerson.relationshipWithApplicant}
              placeholder="Enter Relationship with Applicants"
              disabled={forView}
              onChange={e =>
                handleDetailChange(index, "relationshipWithApplicant", e.target.value)
              }
            />
            <InputImageField
              label="Reference Letter"
              compulsory={true}
              disabled={forView}
              value={refferPerson.coverLetterImageUrl}
              placeholder="Upload Reference Letter"
              handleFileRemove={() => {}}
              onChange={(e) => {}}
            />
            {!forView && (
              <div className="col col-sm-12 col-md-4 col-lg-4 mb-2 d-flex align-items-end">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleRemoveRefference(index)}
                >
                  <MdCancel />
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
      {!forView ? (
        <button
          type="button"
          className="btn btn-success my-2"
          onClick={handleAddReffernece}
        >
          <IoMdAdd /> Add Reference
        </button>
      ) : null}
    </React.Fragment>
  )
}
