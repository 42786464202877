import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  reimbursementFetchAPI,
  reimbursementCreateAPI,
  donationUpdateAPI,
  donationDeleteAPI,
} from "../../../api/donation/reimbursementApiHelper"
import Notify from "components/Common/notify"

export const ReimbursementFetch = createAsyncThunk(
  "Donation/ReimbursementFetch",
  async data => {
    try {
      const response = await reimbursementFetchAPI(data)
      const _response = {
        data: response?.data,
        status: response?.status,
      }
      return _response
    } catch (error) {
      Notify("error", "Failed to fetch Disbursement Data")
      throw Error("Failed to fetch Disbursement Data")
    }
  },
)
export const ReimbursementCreate = createAsyncThunk(
  "Donation/ReimbursementCreate",
  async data => {
    try {
      const response = await reimbursementCreateAPI(data)
      console.log("create staff response", response)
      if (response?.status === 200 || response?.status === 201) {
        Notify("success", "Disbursement added successfully.")
        return response.data
      } else {
        Notify("error", "Failed to create Disbursement")
        throw Error("Failed to create Disbursement")
      }
    } catch (error) {
      Notify("error", "Failed to create Disbursement")
      throw Error("Failed to create Disbursement")
    }
  },
)
export const DonationUpdate = createAsyncThunk(
  "Donation/DonationUpdate",
  async ({ id: id, data: data }) => {
    try {
      const response = await donationUpdateAPI({ id: id, data: data })
      if (response?.status === 200) {
        Notify("success", "Staff member updated.")
        return response.data
      } else {
        Notify("error", "Failed to update staff member")
        throw Error("Failed to update staff member")
      }
    } catch (error) {
      Notify("error", "Failed to update staff member")
      throw Error("Failed to update staff member")
    }
  },
)
export const DonationDelete = createAsyncThunk(
  "Donation/DonationDelete",
  async id => {
    try {
      const response = await donationDeleteAPI(id)
      if (response?.status === 200) {
        Notify("success", "Staff member deleted.")
        return response.data
      } else {
        Notify("error", "Failed to delete staff member")
        throw Error("Failed to delete staff member")
      }
    } catch (error) {
      Notify("error", "Failed to delete staff member")
      throw Error("Failed to delete staff member")
    }
  },
)

const initialState = {
  reimbursementData: [
    // {
    //   ID: 101,
    //   Name: "John Doe",
    //   Phone: "+1-234-567-8901",
    //   Currency: "USD",
    //   DisburseAmount: 1500.00,
    //   CanadianAmount: 2000.00,
    //   DisburseDate: "2024-01-15"
    // },
    // {
    //   ID: 102,
    //   Name: "Jane Smith",
    //   Phone: "+1-345-678-9012",
    //   Currency: "CAD",
    //   DisburseAmount: 1800.00,
    //   CanadianAmount: 1800.00,
    //   DisburseDate: "2024-02-18"
    // },
    // {
    //   ID: 103,
    //   Name: "Alice Johnson",
    //   Phone: "+1-456-789-0123",
    //   Currency: "EUR",
    //   DisburseAmount: 2200.00,
    //   CanadianAmount: 3100.00,
    //   DisburseDate: "2024-03-25"
    // },
    // {
    //   ID: 104,
    //   Name: "Michael Brown",
    //   Phone: "+1-567-890-1234",
    //   Currency: "GBP",
    //   DisburseAmount: 1300.00,
    //   CanadianAmount: 2200.00,
    //   DisburseDate: "2024-04-11"
    // },
    // {
    //   ID: 105,
    //   Name: "Emma Wilson",
    //   Phone: "+1-678-901-2345",
    //   Currency: "USD",
    //   DisburseAmount: 1700.00,
    //   CanadianAmount: 2300.00,
    //   DisburseDate: "2024-05-20"
    // },
    // {
    //   ID: 106,
    //   Name: "Liam Martinez",
    //   Phone: "+1-789-012-3456",
    //   Currency: "CAD",
    //   DisburseAmount: 1400.00,
    //   CanadianAmount: 1400.00,
    //   DisburseDate: "2024-06-14"
    // },
    // {
    //   ID: 107,
    //   Name: "Sophia Taylor",
    //   Phone: "+1-890-123-4567",
    //   Currency: "AUD",
    //   DisburseAmount: 2100.00,
    //   CanadianAmount: 1800.00,
    //   DisburseDate: "2024-07-23"
    // },
    // {
    //   ID: 108,
    //   Name: "Mason Lee",
    //   Phone: "+1-901-234-5678",
    //   Currency: "CAD",
    //   DisburseAmount: 2500.00,
    //   CanadianAmount: 2500.00,
    //   DisburseDate: "2024-08-10"
    // },
    // {
    //   ID: 109,
    //   Name: "Isabella Jones",
    //   Phone: "+1-012-345-6789",
    //   Currency: "JPY",
    //   DisburseAmount: 300000.00,
    //   CanadianAmount: 2600.00,
    //   DisburseDate: "2024-09-02"
    // },
    // {
    //   ID: 110,
    //   Name: "Noah Brown",
    //   Phone: "+1-234-567-8901",
    //   Currency: "EUR",
    //   DisburseAmount: 1200.00,
    //   CanadianAmount: 1600.00,
    //   DisburseDate: "2024-10-19"
    // }
  ],
  totalPages: 1,
  loading: false,
  error: null,
}

export const ReimbursementSlice_ = createSlice({
  name: "ReimbursementSlice_",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(ReimbursementFetch.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(ReimbursementFetch.fulfilled, (state, action) => {
        console.log("Donation fetch payload", action.payload?.data?.items)
        state.loading = false
        state.totalPages = action.payload?.data?.totalPages
        state.reimbursementData = action.payload?.data?.items?.items
      })
      .addCase(ReimbursementFetch.rejected, (state, action) => {
        console.log("error staff fetch", action.error)
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(ReimbursementCreate.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(ReimbursementCreate.fulfilled, (state, action) => {
        console.log("Donation create payload", action.payload)
        state.loading = false
        // state.donorMasterData = [...state.donorMasterData, action.payload.data]
      })
      .addCase(ReimbursementCreate.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(DonationUpdate.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(DonationUpdate.fulfilled, (state, action) => {
        state.loading = false
        console.log("Donation update payload", action.payload)
        if (action.payload) {
          state.donorMasterData = state.donorMasterData.map(st =>
            st.id === action.payload?.data?.id ? action.payload?.data : st,
          )
        }
      })
      .addCase(DonationUpdate.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(DonationDelete.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(DonationDelete.fulfilled, (state, action) => {
        state.loading = false
        console.log("Donation delete payload", action.payload)
        state.donorMasterData = state.donorMasterData.filter(
          i => i?.id !== action.payload?.data,
        )
      })
      .addCase(DonationDelete.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  },
})

export const {} = ReimbursementSlice_.actions

export const allReimbursements_ = state => state.allReimbursements_

export default ReimbursementSlice_.reducer
