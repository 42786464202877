import { axiosInstance, instanceAuth } from "api/apiConfig"

export const donationFetchAPI = async data => {
  try {
    console.log("Donation get params in api", data)
    const response = await instanceAuth.get("Donation/GetDonations", {
      params: {
        ...data,
      },
    })
    return response
  } catch (error) {
    console.log("error fetching donation data...", error)
    throw error
  }
}

export const donationCreateAPI = async data => {
  console.log("Donation staff in helper", data)
  try {
    const response = await instanceAuth.post("Donation/AddDonation", data)
    return response
  } catch (error) {
    console.log("error creating donation...", error)
    throw error
  }
}
