import { axiosInstance, instanceAuth } from "api/apiConfig"

export const profileDataFetchAPI = async id => {
  try {
    // console.log("staff get params in api", id)
    const response = await instanceAuth.get(`User/${id}`)
    return response
  } catch (error) {
    console.log("error fetching staff data...", error)
    throw error
  }
}
