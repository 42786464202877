import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Notify from "components/Common/notify"
import { studentsFetchAPI } from "api/students/studentsApiHelper"

export const StudentsFetch = createAsyncThunk(
  "Students/StudentsFetch",
  async data => {
    try {
      const response = await studentsFetchAPI(data)
      const _response = {
        data: response?.data,
        status: response?.status,
      }
      return _response
    } catch (error) {
      Notify("error", "Failed to fetch Students Data")
      throw Error("Failed to fetch Students Data")
    }
  },
)

const initialState = {
  studentsData: [],
  totalPages: 1,
  loading: false,
  error: null,
}

export const StudentsSlice = createSlice({
  name: "StudentsSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(StudentsFetch.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(StudentsFetch.fulfilled, (state, action) => {
        console.log("Students fetch payload", action.payload?.data)
        state.loading = false
        state.totalPages = action.payload?.data?.data?.totalPages
        state.studentsData = action.payload?.data?.data?.items
      })
      .addCase(StudentsFetch.rejected, (state, action) => {
        console.log("error students fetch", action.error)
        state.loading = false
        state.error = action.error.message
      })
  },
})

export const {} = StudentsSlice.actions

export const allStudents = state => state.allStudents

export default StudentsSlice.reducer
