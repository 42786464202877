import React, { useState } from "react"
import { Link } from "react-router-dom"
import "./sideNav.scss"
import { FaLock } from "react-icons/fa"
import { FiPlus, FiMinus } from "react-icons/fi"

export default function NavLinks({
  linkName,
  link,
  icon,
  subNavs,
  activeStates,
  setActiveStates,
}) {
  const [show, setShow] = useState(false)
  function toggleSidebarInSmallScreen() {
    const body = document.body;
    const isSmallScreen = window.innerWidth < 992;
    if (isSmallScreen) {
      body?.classList?.toggle("vertical-collpsed");
      body?.classList?.toggle("sidebar-enable");
    }
  }

  return (
    <li className="py-2">
      <Link
        className={`d-flex align-items-center justify-content-between poppins-semibold linkMainLi text-secondary ${subNavs === undefined ? "has-arrow" : ""} waves-effect ${activeStates.main === linkName ? "linkMainLiActive" : "text-secondary"}`}
        to={subNavs === undefined && link}
        onClick={
          subNavs !== undefined
            ? () => setShow(!show)
            : () =>{
                setActiveStates({
                  main: linkName,
                  child: "",
                }); toggleSidebarInSmallScreen()}
        }
      >
        <div>
          <span className="px-2">{icon}</span>
          <span className="text-nowrap">{linkName}</span>
        </div>
        {subNavs ? (
          <span className="">
            {show ? <FiMinus size={15} /> : <FiPlus size={15} />}
          </span>
        ) : (
          ""
        )}
      </Link>
      {/* <div> */}
      {subNavs && subNavs?.length > 0 ? (
        <ul
          className={`flex-column justify-content-start align-items-start sub-menu linkUl ${
            show ? "show" : ""
          }`}
        >
          {subNavs?.map((nav, i) => (
            <li className="p-1 list-item" key={i}>
              <Link
                className={`poppins-medium font-size-13 d-flex font-weight-light w-100 ${
                  activeStates.child === nav.linkName
                    ? "linkUlActive"
                    : "text-secondary"
                }`}
                to={nav.link}
                onClick={() =>{
                  setActiveStates({
                    main: linkName,
                    child: nav.linkName,
                  }); toggleSidebarInSmallScreen()}
                }
              >
                {nav.link ? (
                  <div className="d-flex gap-1 text-nowrap">
                    <span>{nav.subNavIcon}</span>
                    {nav.linkName}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <FaLock style={{ pointerEvents: "none", color: "grey" }} />

                    <span
                      style={{
                        pointerEvents: "none",
                        color: "grey",
                      }}
                    >
                      {nav.linkName}
                    </span>
                  </div>
                )}
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
      {/* </div> */}
    </li>
  )
}
