import React, { createContext, useState, useEffect } from "react"
const SidebarContext = createContext()

export const SidebarProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  const [applicationFilters, setApplicationFilters] = useState({
    status: "",
  })
  const [activeStates, setActiveStates] = useState({
    main: "Dashboard",
    child: "",
  })

  useEffect(() => {
    console.log("Active state changed::", activeStates)
    setCurrentPage(1)
    if(activeStates?.main !== "Application List"){
      setApplicationFilters((prev)=>({
        ...prev,
        status: ""
      }))
    }
  }, [activeStates?.main, activeStates?.child])

  return (
    <SidebarContext.Provider
      value={{
        activeStates,
        setActiveStates,
        currentPage,
        setCurrentPage,
        itemsPerPage,
        setItemsPerPage,
        applicationFilters,
        setApplicationFilters
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

export default SidebarContext
