import { TextareaField } from "components/Common/input-fields"
import Notify from "components/Common/notify"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import Select from "react-select"
import { Card, CardBody, CardFooter, CardTitle, Col, Row } from "reactstrap"
import { SHUK_AUTH_PORTAL_TOKEN } from "redux/enums"
import {
  allApplications,
  AssignStaff,
  GetApplicationComments,
  SendApplicationComment,
  UpdateAssignStaff,
} from "redux/slice/applications/applicationSlice"
import { allStaffs, StaffFetch } from "redux/slice/staff/staffSlice"
import { DateFormater, DateTimeFormatter } from "utilities/dateformater"
import { parseJwt } from "utilities/parse-jwt"

export default function ApplicationComments({
  // handleCommentsModal,
  // selectedApplication,
}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { applicationComments, currentApplicationSelected } = useSelector(allApplications)

  const [comment, setComment] = useState("");

  useEffect(() => {
    console.log("comments loading......", currentApplicationSelected)
    if(currentApplicationSelected && Object.keys(currentApplicationSelected)?.length > 0 && currentApplicationSelected?.id)
    dispatch(GetApplicationComments(currentApplicationSelected?.id))
  }, [currentApplicationSelected])

  const handleSendComment = async () => {
    let dateTime = new Date().toISOString()
    const token = localStorage.getItem(SHUK_AUTH_PORTAL_TOKEN)
    if(!comment){
      Notify("error", "Please write comment first.")
    } else if(!token){
      Notify("error", "Please login first.")
      navigate("/login")
    } else if(!currentApplicationSelected || Object.keys(currentApplicationSelected)?.length === 0){
      Notify("error", "No application selected.")
    } else {
      let userId = await parseJwt(token)
      let commentsData = {
        StaffId:
          userId?.["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
        studentId: currentApplicationSelected?.id,
        commentData: {
          comments: comment,
          time: dateTime,
        },
      }
      dispatch(SendApplicationComment(commentsData))
        .then(resp => {
          console.log(
            "response of sending comment in application",
            resp?.payload,
          )
          if (resp?.payload?.status === 200) {
            // handleCommentsModal();
            dispatch(GetApplicationComments(currentApplicationSelected?.id))
            Notify("success", "Comment sent.")
            setComment("")
          }
        })
        .catch(err => {
          console.log("Error sending comment", err)
        })
      console.log("new comments data", commentsData)
    }
  }

  return (
    <Card className="bg-white application-comments-container">
      <CardBody
        className="mt-2 comments-body"
      >
        <CardTitle tag="h3" className="text-secondary-si fs-3">
          Internal Notes
        </CardTitle>
        <div>
          {applicationComments?.length > 0 ? [...applicationComments].reverse().map((com, index) => (
            <div className="comment-box">
              <h5 className="comment-author-name">{com?.authorName}</h5>
              <p className="comment-para" style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{com?.comments}</p>
              <p className="comment-date">{DateTimeFormatter(com?.time)}</p>
            </div>
          )) : <p className="text-center fs-4 mt-4 text-muted">No comments yet</p>}
        </div>
        <Row>
        </Row>
      </CardBody>
      <CardFooter className="comment-footer">
        <div className="">
          <TextareaField
              label=""
              className="mb-2"
              compulsory={false}
              disabled={false}
              rows="10"
              value={comment}
              placeholder="Enter Comments"
              onChange={e => setComment(e.target.value)}
            />
          <button className="btn btn-success-sec " style={{width: "100px"}} onClick={handleSendComment}>
            Send
            <i className="mdi mdi-send float-end"></i>
          </button>
        </div>
        {/* <div className="mt-4 float-end">          
          <button className="btn btn-danger" onClick={handleCommentsModal}>
            Close
          </button>
        </div> */}
      </CardFooter>
    </Card>
  )
}
