import { combineReducers, configureStore } from "@reduxjs/toolkit"
import allApplications from "redux/slice/applications/applicationSlice"
import allDonarMaster from "redux/slice/donation/donationMaster"
import allStaffs from "redux/slice/staff/staffSlice"
import allStudents from "redux/slice/students/studentsSlice"
import ForgotPasswordSlice from "redux/slice/user/forgotPasswordSlice"
import userSlice from "redux/slice/user/userSlice"
import allDonations_ from "redux/slice/donation/donations_"
import allReimbursements_ from "redux/slice/donation/reimbursement"
import ChatSlice from "redux/slice/chats/chatSlice"

const rootReducer = combineReducers({
  user: userSlice,
  allStaffs: allStaffs,
  allStudents: allStudents,
  allApplications: allApplications,
  allDonarMaster: allDonarMaster,
  allDonations_: allDonations_,
  allReimbursements_: allReimbursements_,
  ForgotPasswordBlock: ForgotPasswordSlice,
  allChats: ChatSlice,
})

export const store = configureStore({
  reducer: rootReducer,
})
