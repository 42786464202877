import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  staffFetchAPI,
  staffCreateAPI,
  staffUpdateAPI,
  staffDeleteAPI,
} from "../../../api/staff/staffApiHelper"
import Notify from "components/Common/notify"
import { profileDataFetchAPI } from "api/profile/profileApiHelper"

export const StaffFetch = createAsyncThunk("Staff/StaffFetch", async data => {
  try {
    const response = await staffFetchAPI(data)
    const _response = {
      data: response?.data,
      status: response?.status,
    }
    return _response
  } catch (error) {
    Notify("error", "Failed to fetch Staff Data")
    throw Error("Failed to fetch Staff Data")
  }
})
export const StaffCreate = createAsyncThunk("Staff/StaffCreate", async data => {
  try {
    const response = await staffCreateAPI(data)
    console.log("create staff response", response)
    const _response = {
      data: response?.data,
      status: response?.status,
    }
    // if (response?.status === 200 || response?.status === 201) {
      // Notify("success", "Staff added successfully.")
    return _response
    // } else {
    //   Notify("error", "Failed to create staff member")
    //   throw Error("Failed to create staff member")
    // }
  } catch (error) {
    console.log(" error in create staff", error)
    let errorMsg = error?.response?.data?.message || "Failed to create staff"
    Notify("error", errorMsg)
    return error
  }
})
export const StaffUpdate = createAsyncThunk(
  "Staff/StaffUpdate",
  async ({ id: id, data: data }) => {
    try {
      const response = await staffUpdateAPI({ id: id, data: data })
      const _response = {
        data: response?.data,
        status: response?.status,
      }
      return _response
      // if (response?.status === 200) {
      //   Notify("success", "Staff member updated.")
      //   return response.data
      // } else {
      //   Notify("error", "Failed to update staff member")
      //   throw Error("Failed to update staff member")
      // }
    } catch (error) {
      let errorMsg = error?.response?.data?.message || "Failed to update staff"
      Notify("error", errorMsg)
      return error
    }
  },
)
export const StaffDelete = createAsyncThunk("Staff/StaffDelete", async id => {
  try {
    const response = await staffDeleteAPI(id)
    if (response?.status === 200) {
      Notify("success", "Staff member deleted.")
      return response.data
    } else {
      Notify("error", "Failed to delete staff member")
      throw Error("Failed to delete staff member")
    }
  } catch (error) {
    Notify("error", "Failed to delete staff member")
    throw Error("Failed to delete staff member")
  }
})

export const ProfileDataFetch = createAsyncThunk("Profile/ProfileDataFetch", async id => {
  try {
    const response = await profileDataFetchAPI(id)
    const _response = {
      data: response?.data,
      status: response?.status,
    }
    return _response
  } catch (error) {
    Notify("error", "Failed to fetch profile data")
    throw Error("Failed to fetch profile data")
  }
})

const initialState = {
  staffMembersData: [
    // {
    //   id: 1,
    //   userName: "jdoe",
    //   firstName: "John",
    //   middleName: "A.",
    //   lastName: "Doe",
    //   role: "Admin",
    //   email: "jdoe@example.com",
    //   phoneNumber: "+923001234567",
    //   dateOfBirth: "1990-01-01",
    //   password: "password123",
    //   confirmPassword: "password123",
    // },
    // {
    //   id: 2,
    //   userName: "asmith",
    //   firstName: "Alice",
    //   middleName: "B.",
    //   lastName: "Smith",
    //   role: "Review Committee",
    //   email: "asmith@example.com",
    //   phoneNumber: "+923007654321",
    //   dateOfBirth: "1985-05-15",
    //   password: "alicepass",
    //   confirmPassword: "alicepass",
    // },
    // {
    //   id: 3,
    //   userName: "bjones",
    //   firstName: "Bob",
    //   middleName: "C.",
    //   lastName: "Jones",
    //   role: "Board Member",
    //   email: "bjones@example.com",
    //   phoneNumber: "+923001122334",
    //   dateOfBirth: "1992-07-20",
    //   password: "bobsecure",
    //   confirmPassword: "bobsecure",
    // },
  ],
  profileData: {},
  totalPages: 1,
  loading: false,
  error: null,
}

export const StaffSlice = createSlice({
  name: "StaffSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(StaffFetch.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(StaffFetch.fulfilled, (state, action) => {
        console.log("Staff fetch payload", action.payload?.data?.items)
        state.loading = false
        state.totalPages = action.payload?.data?.totalPages
        state.staffMembersData = action.payload?.data?.items
      })
      .addCase(StaffFetch.rejected, (state, action) => {
        console.log("error staff fetch", action.error)
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(StaffCreate.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(StaffCreate.fulfilled, (state, action) => {
        console.log("Staff create payload", action.payload)
        if(action.payload?.status === 201){
          state.loading = false
          state.staffMembersData = [...state.staffMembersData, action.payload?.data?.data]
          // state.error = action.payload?.response?.data?.message
        }
      })
      .addCase(StaffCreate.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(StaffUpdate.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(StaffUpdate.fulfilled, (state, action) => {
        state.loading = false
        console.log("Staff update payload", action.payload)
        if (action.payload && action.payload?.status === 200) {
          state.staffMembersData = state.staffMembersData.map(st =>
            st.id === action.payload?.data?.id ? action.payload?.data : st,
          )
        }
      })
      .addCase(StaffUpdate.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(StaffDelete.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(StaffDelete.fulfilled, (state, action) => {
        state.loading = false
        console.log("Staff delete payload", action.payload)
        state.staffMembersData = state.staffMembersData.filter(
          i => i?.id !== action.payload?.data,
        )
      })
      .addCase(StaffDelete.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(ProfileDataFetch.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(ProfileDataFetch.fulfilled, (state, action) => {
        state.loading = false
        console.log("profile fetch payload", action.payload?.data)
        state.profileData = action.payload?.data
      })
      .addCase(ProfileDataFetch.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  },
})

export const {} = StaffSlice.actions

export const allStaffs = state => state.allStaffs

export default StaffSlice.reducer
