import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap"
import { MdDelete, MdModeEdit } from "react-icons/md"
import { useContext, useEffect, useMemo, useState } from "react"
import {
  DataFilter,
  ItemsPerPage,
  SearchFilter,
} from "components/Common/filters"
import { useSelector, useDispatch } from "react-redux"
import PaginationComponent from "components/Common/pagination"
import Loader from "components/Common/loader"
import { allStudents, StudentsFetch } from "redux/slice/students/studentsSlice"
import { calculateAge, DateFormater, DateTimeFormatter } from "utilities/dateformater"
import SidebarContext from "context/sidebar.context"

export default function StudentsTable(props) {
  const dispatch = useDispatch()
  const { studentsData, loading, totalPages } = useSelector(allStudents)
  const { currentPage, setCurrentPage, itemsPerPage, setItemsPerPage } = useContext(SidebarContext)
  // const [itemsPerPage, setItemsPerPage] = useState(5)
  // const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState("")
  const [filters, setFilters] = useState({})

  const searchedData = useMemo(() => {
    return studentsData.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );  
  }, [studentsData, searchTerm]);

  const paginate = pageNumber => {
    console.log("paginate call with page number: ", pageNumber)
    setCurrentPage(pageNumber)
    let data = {
      pageNumber: pageNumber,
      pageSize: itemsPerPage,
    }
    dispatch(StudentsFetch(data))
  }

  useEffect(() => {
    let data = {
      pageNumber: currentPage,
      pageSize: itemsPerPage,
    }
    dispatch(StudentsFetch(data))
  }, [itemsPerPage, filters])

  const handleItemsPerPage = perPage => {
    console.log("items per page", perPage)
    if(perPage !== itemsPerPage){
      setCurrentPage(1)
    }
    setItemsPerPage(Number(perPage))
  }

  const handleUpdate = received => {
    console.log("update", received)
    props.forUpdate(received)
  }

  const handleDelete = () => {
    console.log("deleting")
  }

  return (
    <div className="application-table-container">
      <Row>
        <Col lg={12}>
          <Card style={{minHeight: "50vh"}}>
            <CardTitle className="fs-2 ms-3 dot-after">Students</CardTitle>
            <CardBody>
              <div
                className="d-flex justify-content-end flex-row align-items-center"
                style={{ width: "100%" }}
              >
                <div className="me-3">
                  <SearchFilter
                    value={searchTerm}
                    label="Search Student"
                    onChange={e => {
                      setSearchTerm(e.target.value)
                    }}
                  />
                </div>
                <div>
                  <ItemsPerPage
                    value={itemsPerPage}
                    handleItemsPerPage={handleItemsPerPage}
                  />
                </div>
              </div>

              <div className="" style={{minHeight: "48vh", maxHeight: "48vh", overflowX: "auto", overflowY: "auto", position: "relative" }}>
                {loading ? (
                  <Loader />
                ) : (
                  <Table className="table mb-0 table-striped table-bordered h-100">
                    <thead>
                      <tr>
                        <th className="text-nowrap">Application Id</th>
                        <th className="text-nowrap">Student Name</th>
                        <th className="text-nowrap">Email</th>
                        <th className="text-nowrap">Contact Number</th>
                        <th className="text-nowrap">Date Of Birth</th>
                        <th className="text-nowrap">Age</th>
                        <th className="text-nowrap">Address</th>
                        <th className="text-nowrap">Status</th>
                        <th className="text-nowrap">Date Created</th>
                        <th className="text-nowrap">Program Length</th>
                        {/* <th className="text-nowrap">Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {searchedData.map((std, index) => (
                        <tr key={index}>
                          <td className="text-nowrap">{std.applicationId}</td>
                          <td className="text-nowrap">
                            {std?.firstName
                              ? std?.firstName + " " + std?.lastName
                              : "--"}
                          </td>
                          <td className="text-nowrap">
                            {std?.email ? std?.email : "--"}
                          </td>
                          <td className="text-nowrap">
                            {std?.phoneNumber ? std?.phoneNumber : "--"}
                          </td>
                          <td className="text-nowrap">
                            {std?.dateOfBirth
                              ? DateFormater(std?.dateOfBirth)
                              : "--"}
                          </td>
                          <td className="text-nowrap">
                            {std?.dateOfBirth
                              ? calculateAge(std?.dateOfBirth)
                              : "--"}
                          </td>
                          <td className="text-nowrap">
                            {std?.homeAddress ? std?.homeAddress : "--"}
                          </td>
                          <td className="text-nowrap">
                            {std?.applicationStatus ? std?.applicationStatus : "--"}
                          </td>
                          <td className="text-nowrap">
                            {std?.submittedDate ? DateTimeFormatter(std?.submittedDate) : "--"}
                          </td>
                          <td className="text-nowrap">
                            {std?.programDuration ? std?.programDuration : "--"}
                          </td>
                          {/* <td className="d-flex">
                              <MdModeEdit
                                style={{ cursor: "pointer" }}
                                className="me-2 text-primary"
                                onClick={() => handleUpdate(staff)}
                                size={18}
                              />
                              <MdDelete
                                style={{ cursor: "pointer" }}
                                className="text-danger"
                                onClick={() => {}}
                                size={18}
                              />
                            </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
            </CardBody>
            <CardFooter>
              <div className="d-flex justify-content-center justify-content-md-end justify-content-lg-end align-items-center flex-wrap">
                <div style={{ marginLeft: "4px" }}>
                  <PaginationComponent
                    totalPages={totalPages}
                    currentPage={currentPage}
                    paginate={paginate}
                  />
                </div>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
