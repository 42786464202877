import { FaDonate, FaList } from "react-icons/fa"
import { MdDashboard, MdPayments } from "react-icons/md"
import { FaUserTie } from "react-icons/fa"

import { PiStudentFill } from "react-icons/pi"
import { HiOutlineDocumentReport } from "react-icons/hi"
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";

const LEFT_ADMIN_NAVIGATION = [
  { linkName: "Dashboard", link: "/dashboard", icon: <MdDashboard /> },
  {
    linkName: "Staff Member",
    link: "/staff",
    icon: <FaUserTie />,
  },
  {
    linkName: "Application List",
    link: "/applications",
    icon: <FaList />,
  },
  // {
  //   linkName: "Donation",
  //   subNavs: [
  //     {
  //       linkName: "Donar Master",
  //       link: "/donation/donar-master",
  //       subNavIcon: <FaDonate />,
  //     },
  //     {
  //       linkName: "Donations",
  //       /* link: '/Resources/Communities', */ subNavIcon: <FaDonate />,
  //     },
  //     {
  //       linkName: "Disbursements",
  //       /* link: '/Resources/Events', */ subNavIcon: <FaDonate />,
  //     },
  //   ],
  //   icon: <FaDonate />,
  // },
  {
    linkName: "Report",
    subNavs: [
      {
        linkName: "Payment Schedule",
        /* link: '/Report/Payment', */ subNavIcon: <MdPayments />,
      },
    ],
    icon: <HiOutlineDocumentReport />,
  },
  {
    linkName: "Students List",
    icon: <PiStudentFill />,
    link: "/students",
  },
  // {
  //   linkName: "Chat",
  //   icon: <IoChatbubbleEllipsesOutline />,
  //   link: "/chat",
  // },
]

const LEFT_COMITEE_NAVIGATION = [
  { linkName: "Dashboard", link: "/dashboard", icon: <MdDashboard /> },
  // {
  //   linkName: "Staff Member",
  //   link: "/staff",
  //   icon: <FaUserTie />,
  // },
  {
    linkName: "Application List",
    link: "/applications",
    icon: <FaList />,
  },
  // {
  //   linkName: "Donation",
  //   subNavs: [
  //     {
  //       linkName: "Donar Master",
  //       link: "/donation/donar-master",
  //       subNavIcon: <FaDonate />,
  //     },
  //     {
  //       linkName: "Donations",
  //       /* link: '/Resources/Communities', */ subNavIcon: <FaDonate />,
  //     },
  //     {
  //       linkName: "Disbursements",
  //       /* link: '/Resources/Events', */ subNavIcon: <FaDonate />,
  //     },
  //   ],
  //   icon: <FaDonate />,
  // },
  {
    linkName: "Report",
    subNavs: [
      {
        linkName: "Payment Schedule",
        /* link: '/Report/Payment', */ subNavIcon: <MdPayments />,
      },
    ],
    icon: <HiOutlineDocumentReport />,
  },
  {
    linkName: "Students List",
    icon: <PiStudentFill />,
    link: "/students",
  },
  // {
  //   linkName: "Chat",
  //   icon: <IoChatbubbleEllipsesOutline />,
  //   link: "/chat",
  // },
]

export { LEFT_ADMIN_NAVIGATION, LEFT_COMITEE_NAVIGATION }
