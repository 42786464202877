import {
  InputField,
  PasswordInputField,
  PhoneInputField,
  SelectField,
  SelectFieldForId,
  TextareaField,
} from "components/Common/input-fields"
import Notify from "components/Common/notify"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { DonarCreate } from "redux/slice/donation/donationMaster"

export default function DonarMasterForm(props) {
  const dispatch = useDispatch()
  let passwordRegex = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/
  let mobileRegex = /^\+\d{12}$/
  let emailRegex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/
  const [error, setError] = useState("")
  const [createData, setCreateData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    spouseName: "",
    primaryEmail: "",
    secondaryEmail: "",
    primaryPhone: "+92",
    secondaryPhone: "+92",
    primaryAddress: "",
    secondaryAddress:"",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    isBoardMember: false,
    description: "",
  })

  // useEffect(() => {
  //   console.log("for update as a prop ", props?.donarMasterData)
  //   if (
  //     props?.donarMasterData &&
  //     Object.keys(props?.donarMasterData).length !== 0
  //   ) {
  //     setCreateData(props?.donarMasterData)
  //   }
  // }, [])

  const handleChangeInfo = (fieldName, value) => {
    console.log(fieldName, "change to", value)
    setCreateData(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  }

  const handleSubmit = () => {
    console.log("createdata", createData)
    if (
      !createData.firstName ||
      !createData.middleName ||
      !createData.lastName ||
      !createData.spouseName ||
      !createData.primaryEmail ||
      !createData.secondaryEmail ||
      !createData.primaryPhone ||
      !createData.secondaryPhone ||
      !createData.primaryAddress ||
      !createData.secondaryAddress ||
      !createData.country ||
      !createData.state ||
      !createData.city ||
      !createData.zipCode ||
      // !createData.isBoardMember ||
      !createData.description 
    ) {
      Notify("error", "Fill al the fields required fields.")
    } 
    // else if (
    //   (!createData.password || !createData.confirmPassword) &&
    //   !props?.donarMasterData &&
    //   Object.keys(props?.donarMasterData).length === 0
    // ) {
    //   Notify("error", "Please fill passwords fields")
    // } else if (!emailRegex.test(createData.email)) {
    //   Notify("error", "Email address is not valid")
    // } else if (!mobileRegex.test(createData.phoneNumber)) {
    //   Notify(
    //     "error",
    //     "Number should start with +92 and contain 10 digits onwards.",
    //   )
    // } else if (
    //   !passwordRegex.test(createData.password) &&
    //   !(
    //     props?.donarMasterData &&
    //     Object.keys(props?.donarMasterData).length !== 0 &&
    //     !createData.password
    //   )
    // ) {
    //   Notify(
    //     "error",
    //     "Password should be atleast 6 charcters long and contain special character.",
    //   )
    // } else if (
    //   createData.password !== createData.confirmPassword &&
    //   !(
    //     props?.donarMasterData &&
    //     Object.keys(props?.donarMasterData).length !== 0 &&
    //     !createData.password &&
    //     !createData.confirmPassword
    //   )
    // ) {
    //   Notify("error", "Password & confirm password not matched.")
    // }
     else {
      // if (
      //   props.donarMasterData &&
      //   Object.keys(props.donarMasterData).length !== 0
      // ) {
      //   const modifiedData = { ...createData }
      //   if (!modifiedData.password || !modifiedData.confirmPassword) {
      //     modifiedData.password = null
      //     modifiedData.confirmPassword = null
      //   }
      //   dispatch(StaffUpdate({ id: props.updateId, data: modifiedData }))
      //   props.toggleTab()
      // } else {
      //   dispatch(StaffCreate(createData))
      //   props.toggleTab()
      //   // dispatch(StaffCreate({id: props?.updateId, data: createData}))
      // }
        dispatch(DonarCreate(createData))
        .then(result => {
          console.log("resultfromlogin", result)
          if (result?.payload?.items?.status === 200) {
            props.toggleTab()
          } 
        })
        .catch(err => {
          console.log("Login error", err)
        })
    }
  }

  return (
    <div className="card p-2">
      <div className="card-title fs-3 mt-2 mx-4">Add Donar Master</div>
      <div className="card-body">
        <div className="row">
          <InputField
            label="First Name"
            placeholder="Enter first name"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.firstName}
            onChange={e => handleChangeInfo("firstName", e.target.value)}
          />
          <InputField
            label="Middle Name"
            placeholder="Enter middle name"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={false}
            disabled={props?.viewState}
            type="text"
            value={createData.middleName}
            onChange={e => handleChangeInfo("middleName", e.target.value)}
          />
          <InputField
            label="Last Name"
            placeholder="Enter last name"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={false}
            disabled={props?.viewState}
            type="text"
            value={createData.lastName}
            onChange={e => handleChangeInfo("lastName", e.target.value)}
          />
          <InputField
            label="Spouse"
            placeholder="Enter spouse name"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={false}
            disabled={props?.viewState}
            type="text"
            value={createData.spouseName}
            onChange={e => handleChangeInfo("spouseName", e.target.value)}
          />
          <InputField
            label="Primary Email"
            placeholder="Enter primary email"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="email"
            value={createData.primaryEmail}
            onChange={e => {
              handleChangeInfo("primaryEmail", e.target.value)
            }}
          />
          <InputField
            label="Secondary Email"
            placeholder="Enter secondary email"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="email"
            value={createData.secondaryEmail}
            onChange={e => {
              handleChangeInfo("secondaryEmail", e.target.value)
            }}
          />
          <PhoneInputField
            label="Primary Phone Number"
            placeholder="Enter primary phone"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            value={createData.primaryPhone}
            onChange={value => handleChangeInfo("primaryPhone", value)}
          />
          <PhoneInputField
            label="Secondary Phone Number"
            placeholder="Enter secondary phone"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            value={createData.secondaryPhone}
            onChange={value => handleChangeInfo("secondaryPhone", value)}
          />
           <SelectField
            label="Board Member"
            placeholder="Enter board member"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={false}
            selectOptions= {["Yes", "No"]}
            disabled={props?.viewState}
            type="text"
            value={createData.isBoardMember ? "Yes": "No"}
            onChange={e => handleChangeInfo("isBoardMember", e.target.value === "Yes")}
          />
         
          <InputField
            label="Country"
            placeholder="Enter country"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.country}
            onChange={e => handleChangeInfo("country", e.target.value)}
          />
          <InputField
            label="State"
            placeholder="Enter state"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.state}
            onChange={e => handleChangeInfo("state", e.target.value)}
          />
          <InputField
            label="City"
            placeholder="Enter city"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.city}
            onChange={e => handleChangeInfo("city", e.target.value)}
          />
          <InputField
            label="Zip Code"
            placeholder="Enter zip code"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.zipCode}
            onChange={e => handleChangeInfo("zipCode", e.target.value)}
          />
          <InputField
            label="Address"
            placeholder="Enter address"
            className="col-sm-12 col-md-6 col-lg-8 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.primaryAddress}
            onChange={e => handleChangeInfo("primaryAddress", e.target.value)}
          />
          <InputField
            label="Address"
            placeholder="Enter address"
            className="col-sm-12 col-md-6 col-lg-8 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.secondaryAddress}
            onChange={e => handleChangeInfo("secondaryAddress", e.target.value)}
          />
          <TextareaField
            label="Note/Comments"
            placeholder="Type note or comments"
            className="col-sm-12 col-md-12 col-lg-12 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.description}
            onChange={e => handleChangeInfo("description", e.target.value)}
          />
        </div>
        {props?.viewState ? null : (
          <div className="d-flex justify-content-end mt-4">
            <button
              className="btn btn-danger me-2"
              onClick={() => props.toggleTab()}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={handleSubmit}>
              {props?.donarMasterData &&
              Object.keys(props?.donarMasterData).length !== 0
                ? "Update"
                : "Add"}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
