import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { InputDocField, InputImageField, TextareaField } from "components/Common/input-fields"

export default function DocumentsInfoForm({
  tabs,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  handleFileRemove,
  documentsInfo,
  forView,
}) {
  useEffect(()=>{
    console.log("documentsInfo", documentsInfo)
  }, [documentsInfo])
  let infoObject = "documents"
  return (
    <React.Fragment>
      <h5 className="mt-4 text-secondary-si section-heading page-break">
        Documents Details
      </h5>

      <InputImageField
        label="NIC Image"
        compulsory={true}
        disabled={forView}
        value={documentsInfo.nicImage}
        placeholder="Upload NIC Image"
        handleFileRemove={() => handleFileRemove(infoObject, "nicImage")}
        onChange={e => handleFileChange(infoObject, "nicImage", e)}
      />
      <InputImageField
        label="Passport Size Picture"
        compulsory={true}
        disabled={forView}
        value={documentsInfo.passportSizePicture}
        placeholder="Upload Passport Size Picture"
        handleFileRemove={() =>
          handleFileRemove(infoObject, "passportSizePicture")
        }
        onChange={e => handleFileChange(infoObject, "passportSizePicture", e)}
      />
      <InputImageField
        label="Admission Letter"
        compulsory={true}
        disabled={forView}
        value={documentsInfo.addmissionLettter}
        placeholder="Upload Admission Letter"
        handleFileRemove={() =>
          handleFileRemove(infoObject, "addmissionLettter")
        }
        onChange={e => handleFileChange(infoObject, "addmissionLettter", e)}
      />
      <InputImageField
        label="Marksheet"
        compulsory={true}
        disabled={forView}
        value={documentsInfo.marksheet}
        placeholder="Upload Marksheet"
        handleFileRemove={() => handleFileRemove(infoObject, "marksheet")}
        onChange={e => handleFileChange(infoObject, "marksheet", e)}
      />
      <InputImageField
        label="Income Verification"
        compulsory={true}
        disabled={forView}
        value={documentsInfo.incomeVerification}
        placeholder="Upload Income Verification"
        handleFileRemove={() =>
          handleFileRemove(infoObject, "incomeVerification")
        }
        onChange={e => handleFileChange(infoObject, "incomeVerification", e)}
      />
      <InputImageField
        label="Tuition Fee Schedule"
        compulsory={false}
        disabled={forView}
        value={documentsInfo.tutionFeeSchedule}
        placeholder="Upload Tuition Fee Schedule"
        handleFileRemove={() =>
          handleFileRemove(infoObject, "tutionFeeSchedule")
        }
        onChange={e => handleFileChange(infoObject, "tutionFeeSchedule", e)}
      />
      <InputImageField
        label="Fee Receipt"
        compulsory={false}
        disabled={forView}
        value={documentsInfo.feeReceiptsImage}
        placeholder="Upload Fee Receipt"
        handleFileRemove={() =>
          handleFileRemove(infoObject, "feeReceiptsImage")
        }
        onChange={e => handleFileChange(infoObject, "feeReceiptsImage", e)}
      />
      <InputImageField
        label="Miscellaneous Expense"
        compulsory={false}
        disabled={forView}
        value={documentsInfo.miscellaneousExpense}
        placeholder="Upload Miscellaneous Expense"
        handleFileRemove={() =>
          handleFileRemove(infoObject, "miscellaneousExpense")
        }
        onChange={e => handleFileChange(infoObject, "miscellaneousExpense", e)}
      />
      {/* <InputImageField
        label="Other Documents"
        compulsory={false}
        disabled={forView}
        value={documentsInfo.otherDocuments}
        placeholder="Upload Other Document"
        handleFileRemove={() => handleFileRemove(infoObject, "otherDocuments")}
        onChange={e => handleFileChange(infoObject, "otherDocuments", e)}
      /> */}
      {
        documentsInfo.description && (
        <TextareaField
          label="Miscellaneous Detail"
          placeholder="Enter Miscellaneous Detail"
          className="col-sm-12 col-md-6 col-lg-4 my-2"
          compulsory={true}
          disabled={forView}
          type="text"
          value={documentsInfo.description}
          onChange={e => handleChangeInfo(infoObject, "description", e.target.value)}
        />
      )}
    </React.Fragment>
  )
}
