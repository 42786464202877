import {
  InputField,
  PasswordInputField,
  PhoneInputField,
  SelectField,
  SelectFieldForId,
  TextareaField,
} from "components/Common/input-fields"
import Notify from "components/Common/notify"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { DonationCreate } from "redux/slice/donation/donations_"
import { allDonarMaster, DonarFetch } from "redux/slice/donation/donationMaster"
import { Convert } from "easy-currencies";

export default function DonationsForm(props) {
  const dispatch = useDispatch()
  const { donorMasterData, loading, totalPages } = useSelector(allDonarMaster)
  let passwordRegex = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/
  let mobileRegex = /^\+\d{12}$/
  let emailRegex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/
  const [error, setError] = useState("")
  const [createData, setCreateData] = useState({
    donarId:"",
    currency: "",
    donationAmount: "",
    donationCanadianAmount: "",
    donationDate: "",
   
  })
  const [rates, setRates] = useState({});


  console.log("donorMasterData IN DOANTIONS___",donorMasterData)


 const API_URL = "https://v6.exchangerate-api.com/v6/9ace38fba2368024037f229f/latest/CAD";

  useEffect(() => {
    console.log("for update as a prop ", props?.donarMasterData)
    if (
      props?.donarMasterData &&
      Object.keys(props?.donarMasterData).length !== 0
    ) {
      setCreateData(props?.donarMasterData)
    }

    dispatch(DonarFetch())

    // Fetch the latest exchange rates
   const fetchRates = async () => {
    try {
      const response = await fetch(API_URL);
      if (!response.ok) throw new Error("Failed to fetch rates");
      const data = await response.json();
      console.log(data.conversion_rates.CAD,"................");
      
      setRates(data.conversion_rates); // Store rates
     //  setLoading(false);
    } catch (err) {
     //  setError(err.message);
     //  setLoading(false);
    }
  };

  fetchRates();
  }, [])

 // Replace with your API key and endpoint


 function currencyConverter(amount){
  const converted = parseFloat(amount) / rates[createData.currency]
  console.log("converted", converted.toFixed(2));
  
  setCreateData(prevState => ({
    ...prevState,
     donationCanadianAmount: converted.toFixed(2),
  }))
 }
 


  const handleChangeInfo = (fieldName, value) => {
    console.log(fieldName, "change to", value)
    

    if(fieldName === "donationAmount") currencyConverter(value)
    setCreateData(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  }

  const handleSubmit = () => {
    console.log("createdata", createData)
    if (

      !createData.donarId ||
      !createData.currency ||
      // !createData.middleName ||
      // !createData.lastName ||
      !createData.donationAmount ||
      !createData.donationCanadianAmount ||
      !createData.donationDate 
    ) {
      Notify("error", "Fill al the fields required fields.")
    } 
     else {
      dispatch(DonationCreate(createData))
      .then(result => {
        console.log("resultfromdonationCreate", result)
        if (result?.payload?.items?.status === 200) {
          props.toggleTab()
        } 
      })
      .catch(err => {
        console.log("Login error", err)
      })
     
    }
  }

  return (
    <div className="card p-2">
      <div className="card-title fs-3 mt-2 mx-4">Add Donations</div>
      <div className="card-body">
        <div className="row">
          
          <SelectFieldForId
            label="Donor Name"
            placeholder="Donor Name"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={false}
            selectOptions= {donorMasterData}
            disabled={props?.viewState}
            type="text"
            value={createData.donarId}
            onChange={e => handleChangeInfo("donarId", e.target.value)}
          />
          <SelectField
            label="Currency"
            placeholder="Currency"
            id="currency"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={false}
            selectOptions= {Object.keys(rates)}
            disabled={props?.viewState}
            type="text"
            value={createData.currency}
            onChange={e => handleChangeInfo("currency", e.target.value)}
          />
          <InputField
            label="Donation Amount"
            placeholder="Donation Amount"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={false}
            disabled={props?.viewState}
            type="text"
            value={createData.donationAmount}
            onChange={e => handleChangeInfo("donationAmount", e.target.value)}
          />
          <InputField
            label="Equivalent Canadian Amount"
            readOnly
            placeholder="Enter Equivalent Canadian Amount"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={false}
            disabled={props?.viewState}
            type="text"
            value={createData.donationCanadianAmount}
            // onChange={e => handleChangeInfo("donationCanadianAmount", e.target.value)}
          />
          <InputField
            label="Donation Date"
            placeholder="Enter primary email"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="date"
            value={createData.donationDate}
            onChange={e => {
              handleChangeInfo("donationDate", e.target.value)
            }}
          />
          {/* <InputField
            label="Secondary Email"
            placeholder="Enter secondary email"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="email"
            value={createData.secondaryEmail}
            onChange={e => {
              handleChangeInfo("secondaryEmail", e.target.value)
              handleChangeInfo("userName", e.target.value)
            }}
          />
          <PhoneInputField
            label="Primary Phone Number"
            placeholder="Enter primary phone"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            value={createData.primaryPhone}
            onChange={value => handleChangeInfo("primaryPhone", value)}
          />
          <PhoneInputField
            label="Secondary Phone Number"
            placeholder="Enter secondary phone"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            value={createData.secondaryPhone}
            onChange={value => handleChangeInfo("secondaryPhone", value)}
          />
          <InputField
            label="Board Member"
            placeholder="Enter board member"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.boardMember}
            onChange={e => handleChangeInfo("boardMember", e.target.value)}
          />
          <InputField
            label="Country"
            placeholder="Enter country"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.country}
            onChange={e => handleChangeInfo("country", e.target.value)}
          />
          <InputField
            label="State"
            placeholder="Enter state"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.state}
            onChange={e => handleChangeInfo("state", e.target.value)}
          />
          <InputField
            label="City"
            placeholder="Enter city"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.city}
            onChange={e => handleChangeInfo("city", e.target.value)}
          />
          <InputField
            label="Zip Code"
            placeholder="Enter zip code"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.zipCode}
            onChange={e => handleChangeInfo("zipCode", e.target.value)}
          />
          <InputField
            label="Address"
            placeholder="Enter address"
            className="col-sm-12 col-md-6 col-lg-8 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.address}
            onChange={e => handleChangeInfo("address", e.target.value)}
          />
          <TextareaField
            label="Note/Comments"
            placeholder="Type note or comments"
            className="col-sm-12 col-md-12 col-lg-12 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.comments}
            onChange={e => handleChangeInfo("comments", e.target.value)}
          /> */}
        </div>
        {props?.viewState ? null : (
          <div className="d-flex justify-content-end mt-4">
            <button
              className="btn btn-danger me-2"
              onClick={() => props.toggleTab()}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={handleSubmit}>
              {props?.donarMasterData &&
              Object.keys(props?.donarMasterData).length !== 0
                ? "Update"
                : "Add"}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
