import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  donationFetchAPI,
  donationCreateAPI,
} from "../../../api/donation/_donationsApiHelper"
import Notify from "components/Common/notify"

export const DonationFetch = createAsyncThunk(
  "Donation/DonationFetch",
  async data => {
    try {
      const response = await donationFetchAPI(data)
      const _response = {
        data: response?.data,
        status: response?.status,
      }
      return _response
    } catch (error) {
      Notify("error", "Failed to fetch Donation Data")
      throw Error("Failed to fetch Donation Data")
    }
  },
)
export const DonationCreate = createAsyncThunk(
  "Donation/DonationCreate",
  async data => {
    try {
      const response = await donationCreateAPI(data)
      console.log("create staff response", response)
      if (response?.status === 200 || response?.status === 201) {
        Notify("success", "Donation added successfully.")
        return response.data
      } else {
        Notify("error", "Failed to create Donation")
        throw Error("Failed to create Donation")
      }
    } catch (error) {
      Notify("error", "Failed to create Donation")
      throw Error("Failed to create Donation")
    }
  },
)
export const DonationUpdate = createAsyncThunk(
  "Donation/DonationUpdate",
  async ({ id: id, data: data }) => {
    // try {
    //   const response = await donationUpdateAPI({ id: id, data: data })
    //   if (response?.status === 200) {
    //     Notify("success", "Staff member updated.")
    //     return response.data
    //   } else {
    //     Notify("error", "Failed to update staff member")
    //     throw Error("Failed to update staff member")
    //   }
    // } catch (error) {
    //   Notify("error", "Failed to update staff member")
    //   throw Error("Failed to update staff member")
    // }
  },
)
export const DonationDelete = createAsyncThunk(
  "Donation/DonationDelete",
  async id => {
    // try {
    //   const response = await donationDeleteAPI(id)
    //   if (response?.status === 200) {
    //     Notify("success", "Staff member deleted.")
    //     return response.data
    //   } else {
    //     Notify("error", "Failed to delete staff member")
    //     throw Error("Failed to delete staff member")
    //   }
    // } catch (error) {
    //   Notify("error", "Failed to delete staff member")
    //   throw Error("Failed to delete staff member")
    // }
  },
)

const initialState = {
  // donationsData: [
  //   {
  //     ID: 201,
  //     Name: "Lily Evans",
  //     Phone: "+1-312-555-0182",
  //     Currency: "USD",
  //     DonationAmount: 1250.00,
  //     CanadianAmount: 1600.00,
  //     DonationDate: "2024-01-10"
  //   },
  //   {
  //     ID: 202,
  //     Name: "Harry Potter",
  //     Phone: "+1-412-555-0192",
  //     Currency: "CAD",
  //     DonationAmount: 750.00,
  //     CanadianAmount: 750.00,
  //     DonationDate: "2024-02-15"
  //   },
  //   {
  //     ID: 203,
  //     Name: "Hermione Granger",
  //     Phone: "+1-212-555-0175",
  //     Currency: "GBP",
  //     DonationAmount: 890.00,
  //     CanadianAmount: 1550.00,
  //     DonationDate: "2024-03-22"
  //   },
  //   {
  //     ID: 204,
  //     Name: "Ron Weasley",
  //     Phone: "+1-213-555-0165",
  //     Currency: "EUR",
  //     DonationAmount: 1200.00,
  //     CanadianAmount: 1700.00,
  //     DonationDate: "2024-04-08"
  //   },
  //   {
  //     ID: 205,
  //     Name: "Ginny Weasley",
  //     Phone: "+1-714-555-0189",
  //     Currency: "AUD",
  //     DonationAmount: 950.00,
  //     CanadianAmount: 850.00,
  //     DonationDate: "2024-05-30"
  //   },
  //   {
  //     ID: 206,
  //     Name: "Neville Longbottom",
  //     Phone: "+1-323-555-0137",
  //     Currency: "USD",
  //     DonationAmount: 1300.00,
  //     CanadianAmount: 1650.00,
  //     DonationDate: "2024-06-25"
  //   },
  //   {
  //     ID: 207,
  //     Name: "Luna Lovegood",
  //     Phone: "+1-818-555-0123",
  //     Currency: "CAD",
  //     DonationAmount: 1100.00,
  //     CanadianAmount: 1100.00,
  //     DonationDate: "2024-07-17"
  //   },
  //   {
  //     ID: 208,
  //     Name: "Draco Malfoy",
  //     Phone: "+1-626-555-0199",
  //     Currency: "JPY",
  //     DonationAmount: 200000.00,
  //     CanadianAmount: 2400.00,
  //     DonationDate: "2024-08-21"
  //   },
  //   {
  //     ID: 209,
  //     Name: "Cho Chang",
  //     Phone: "+1-213-555-0184",
  //     Currency: "EUR",
  //     DonationAmount: 1000.00,
  //     CanadianAmount: 1400.00,
  //     DonationDate: "2024-09-03"
  //   },
  //   {
  //     ID: 210,
  //     Name: "Cedric Diggory",
  //     Phone: "+1-213-555-0190",
  //     Currency: "GBP",
  //     DonationAmount: 1450.00,
  //     CanadianAmount: 2500.00,
  //     DonationDate: "2024-10-12"
  //   }
  // ],
  donationsData:[],
  totalPages: 1,
  loading: false,
  error: null,
}

export const DonationsSlice_ = createSlice({
  name: "DonationsSlice_",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(DonationFetch.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(DonationFetch.fulfilled, (state, action) => {
        console.log("Donation fetch payload__________::::", action.payload?.data?.items)
        state.loading = false
        state.totalPages = action.payload?.data?.totalPages
        state.donationsData = action.payload?.data?.items?.items
      })
      .addCase(DonationFetch.rejected, (state, action) => {
        console.log("error staff fetch", action.error)
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(DonationCreate.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(DonationCreate.fulfilled, (state, action) => {
        console.log("Donation create payload", action.payload)
        state.loading = false
        state.donationsData = [...state.donationsData, action.payload.data]
      })
      .addCase(DonationCreate.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(DonationUpdate.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(DonationUpdate.fulfilled, (state, action) => {
        state.loading = false
        console.log("Donation update payload", action.payload)
        if (action.payload) {
          state.donorMasterData = state.donorMasterData.map(st =>
            st.id === action.payload?.data?.id ? action.payload?.data : st,
          )
        }
      })
      .addCase(DonationUpdate.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(DonationDelete.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(DonationDelete.fulfilled, (state, action) => {
        state.loading = false
        console.log("Donation delete payload", action.payload)
        state.donorMasterData = state.donorMasterData.filter(
          i => i?.id !== action.payload?.data,
        )
      })
      .addCase(DonationDelete.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  },
})

export const {} = DonationsSlice_.actions

export const allDonations_ = state => state.allDonations_

export default DonationsSlice_.reducer
