import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Notify from "components/Common/notify"
import {
  chatMessagesFetchAllStaffAPI,
  chatMessagesFetchAPI,
  chatUsersFetchAPI,
  sendMessageAPI,
  updateChatReadAPI,
} from "api/chat/ChatApiHelper"

export const ChatUsersFetch = createAsyncThunk(
  "Chat/ChatUsersFetch",
  async staff_id => {
    try {
      const response = await chatUsersFetchAPI(staff_id)
      // console.log("response from chat users api", response)
      const _response = {
        data: response?.data,
        status: response?.status,
        staff_id: staff_id,
      }
      return _response
    } catch (error) {
      Notify("error", "Failed to fetch chats data")
      throw Error("Failed to fetch chats data")
    }
  },
)
export const ChatMessagesFetch = createAsyncThunk(
  "Chat/ChatMessagesFetch",
  async data => {
    try {
      const response = await chatMessagesFetchAPI(data)
      // console.log("chat room message response", response)
      const _response = {
        data: response?.data,
        status: response?.status,
      }
      return _response
    } catch (error) {
      Notify("error", "Failed to fetch message")
      throw Error("Failed to fetch message")
    }
  },
)
export const ChatMessagesFetchAllStaff = createAsyncThunk(
  "Chat/ChatMessagesFetchAllStaff",
  async data => {
    try {
      const response = await chatMessagesFetchAllStaffAPI(data)
      // console.log("chat room message for all staff response", response)
      const _response = {
        data: response?.data,
        status: response?.status,
        bodyData: data,
      }
      return _response
    } catch (error) {
      Notify("error", "Failed to fetch message")
      throw Error("Failed to fetch message")
    }
  },
)

export const SendMessage = createAsyncThunk("Chat/SendMessage", async data => {
  try {
    const response = await sendMessageAPI(data)
    const _response = {
      data: response?.data,
      status: response?.status,
      postData: data,
    }
    return _response
  } catch (error) {
    Notify("error", "Failed to send message")
    throw Error("Failed to send message")
  }
})

export const UpdateChatRead = createAsyncThunk(
  "Chat/UpdateChatRead",
  async data => {
    try {
      const response = await updateChatReadAPI(data)
      const _response = {
        data: response?.data,
        status: response?.status,
        postData: data,
      }
      return _response
    } catch (error) {
      // Notify("error", "Failed to send message")
      throw Error("Failed to update read status")
    }
  },
)

const initialState = {
  // chatsUsersList: [
  // chatRoomMessages: [
  //   {
  //     userId: "123456778",
  //     messageId: "new12323id",
  //     message: "Hi",
  //     time: "2024-12-02T06:59:55.648Z",
  //     isRead: false,
  //   },
  //   {
  //     userId: "123456778",
  //     messageId: "new12323id",
  //     message: "Hi",
  //     time: "2024-12-02T06:59:55.648Z",
  //     isRead: false,
  //   },
  //   {
  //     userId: "123456778",
  //     messageId: "new12323id",
  //     message: "A scholarship is financial support awarded to a student, based on academic achievement or other criteria that may include financial need, for the purpose of schooling. There are various types of scholarships – the two most common being merit-based and need-based. The donor or department funding the scholarship sets the criteria for recipient selection, and the grantor specifically defines how the money is to be applied. The funds are used to defray the costs of tuition, books, room, board and other expenses directly tied to a student’s educational cost through the university. ",
  //     time: "2024-12-02T06:59:55.648Z",
  //     isRead: false,
  //   },
  // ],
  chatsUsersList: [],
  chatRoomMessages: [],
  staffId: "",
  studentId: "",
  openChatRoom: {},
  messageCount: 0,
  loadingChats: false,
  loadingMessages: false,
  error: null,
}

export const ChatSlice = createSlice({
  name: "ChatSlice",
  initialState,
  reducers: {
    UpdateChattingPerson: (state, action) => {
      state.openChatRoom = action.payload
    },
    OpenNewChat: (state, action) => {
      let isExist = state.chatsUsersList.find((item)=>item?.studentId === action.payload?.studentId)
      if(!isExist){
        state.chatsUsersList.push(action.payload)
      }
      state.openChatRoom = action.payload
    },
    SetCurrentStaff: (state, action) => {
      // console.log("setting staff id", action.payload)
      state.staffId = action.payload
      // resetting current messages when on application table screen
      state.chatRoomMessages = []
      state.messageCount = 0
    }
  },
  extraReducers(builder) {
    builder
      .addCase(ChatUsersFetch.pending, state => {
        state.loadingChats = true
        state.chatsUsersList = []
        state.error = null
      })
      .addCase(ChatUsersFetch.fulfilled, (state, action) => {
        console.log(
          "Chat users payload",
          action.payload,
        )
        state.loadingChats = false
        if (action.payload?.data?.items?.staffChats) {
          state.chatsUsersList = action.payload?.data?.items?.staffChats?.data
        } else {
          state.chatsUsersList = []
        }
        if(action.payload?.staff_id){
          state.staffId = action.payload?.staff_id
        }
      })
      .addCase(ChatUsersFetch.rejected, (state, action) => {
        state.loadingChats = false
        state.error = action.error?.message
      })
    builder
      .addCase(ChatMessagesFetch.pending, state => {
        state.loadingMessages = true
        state.chatRoomMessages = []
        state.error = null
      })
      .addCase(ChatMessagesFetch.fulfilled, (state, action) => {
        console.log("Chatroom messages payload", action.payload)
        state.loadingMessages = false
        let messagesComing = action.payload?.data?.items?.chats
        if(messagesComing && messagesComing?.length > 0){
          state.chatRoomMessages = action.payload?.data?.items?.chats
        }
      })
      .addCase(ChatMessagesFetch.rejected, (state, action) => {
        state.loadingMessages = false
        state.error = action.error?.message
      })
    builder
      .addCase(ChatMessagesFetchAllStaff.pending, state => {
        // state.loadingMessages = true
        // state.chatRoomMessages = []
        state.error = null
      })
      .addCase(ChatMessagesFetchAllStaff.fulfilled, (state, action) => {
        console.log("Chatroom messages for all staff payload", action.payload)
        // state.loadingMessages = false
        let messagesComing = action.payload?.data?.items?.studentChats?.data?.messages
        // studentId
        if(messagesComing && messagesComing?.length > 0){
          if(action.payload?.data?.items?.studentChats?.studentId !== state.studentId || messagesComing?.length != state.messageCount){
            // console.log("messages coming", messagesComing)
            // console.log("studentId", action.payload?.data?.items?.studentChats?.studentId)
            state.chatRoomMessages = messagesComing
            state.studentId = action.payload?.data?.items?.studentChats?.studentId
            state.messageCount = messagesComing?.length
          }
        }
      })
      .addCase(ChatMessagesFetchAllStaff.rejected, (state, action) => {
        // state.loadingMessages = false
        state.error = action.error?.message
      })
    builder
      .addCase(SendMessage.pending, state => {
        // state.loading = true
        state.error = null
      })
      .addCase(SendMessage.fulfilled, (state, action) => {
        // state.loading = false
        console.log("message send payload", action.payload)
        if (action.payload?.status === 200) {
          let addNewMessage = {
            messageId: "",
            userId: action.payload.postData?.staffId,
            message: action.payload.postData?.communicationJson?.message,
            time: action.payload.postData?.communicationJson?.time,
            isRead: action.payload.postData?.communicationJson?.isRead,
          }
          // console.log("addNewMessage", addNewMessage)
          // state.chatRoomMessages.push(addNewMessage)
          state.chatRoomMessages.unshift(addNewMessage);
        }
      })
      .addCase(SendMessage.rejected, (state, action) => {
        // state.loading = false
        state.error = action.error?.message
      })
    builder
      .addCase(UpdateChatRead.pending, state => {
        // state.loading = true
        state.error = null
      })
      .addCase(UpdateChatRead.fulfilled, (state, action) => {
        // state.loading = false
        console.log("message status Updated", action.payload)
        if (action.payload?.postData) {
          state.chatsUsersList = state.chatsUsersList?.map(list => {
            if (list?.studentId === action.payload?.postData?.studentId) {
              return {
                ...list,
                isRead: true,
              };
            }
            return list;
          });
        }
      })
      .addCase(UpdateChatRead.rejected, (state, action) => {
        // state.loading = false
        state.error = action.error?.message
      })
  },
})

export const { UpdateChattingPerson, OpenNewChat, SetCurrentStaff } = ChatSlice.actions

export const allChatsData = state => state.allChats

export default ChatSlice.reducer
