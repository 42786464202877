import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Modal,
  Row,
  Table,
} from "reactstrap"
import { MdDelete, MdModeEdit } from "react-icons/md"
import { useContext, useEffect, useState } from "react"
import { DataFilter, ItemsPerPage } from "components/Common/filters"
import { useSelector, useDispatch } from "react-redux"
import { allStaffs, StaffDelete, StaffFetch } from "redux/slice/staff/staffSlice"
import PaginationComponent from "components/Common/pagination"
import Loader from "components/Common/loader"
import SidebarContext from "context/sidebar.context"
import { getUserTokenEmail } from "utilities/parse-jwt"
import Notify from "components/Common/notify"
import { SHUK_AUTH_PORTAL_TOKEN } from "redux/enums"
import { useNavigate } from "react-router-dom"

export default function StaffTable(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { staffMembersData, loading, totalPages } = useSelector(allStaffs)
  const { currentPage, setCurrentPage, itemsPerPage, setItemsPerPage } = useContext(SidebarContext)
  // let totalPages = 8
  // const [itemsPerPage, setItemsPerPage] = useState(5)
  // const [currentPage, setCurrentPage] = useState(1)
  const [deleteIdState, setDeleteIdState] = useState(null)
  const [deleteModalState, setDeleteModalState] = useState(false)
  const [filters, setFilters] = useState({
    role: ""
  })

  const paginate = pageNumber => {
    console.log("paginate call with page number: ", pageNumber)
    setCurrentPage(pageNumber)
    let data = {
      pageNumber: pageNumber,
      pageSize: itemsPerPage,
      Role: filters.role ? filters.role : null
    }
    dispatch(StaffFetch(data))
  }

    useEffect(() => {
      let data = {
        pageNumber: currentPage,
        pageSize: itemsPerPage,
        Role: filters.role ? filters.role : null,
      }
      dispatch(StaffFetch(data))
    }, [itemsPerPage, filters])

  const handleItemsPerPage = (perPage) => {
    console.log("perPage", perPage);
    if(perPage !== itemsPerPage){
      setCurrentPage(1)
    }
    setItemsPerPage(Number(perPage));
  }

  const handleUpdate = received => {
    console.log("update", received.id)
    props.forUpdate(received)
  }

  const handleDelete = async() => {
    console.log("deleteIdState", deleteIdState)
    let loginUserEmail =  await getUserTokenEmail()
    dispatch(StaffDelete(deleteIdState?.id))
    .then((staff_del_resp)=>{
      console.log("staff_del_resp", staff_del_resp)
      console.log("loginUserEmail", loginUserEmail)
      if(deleteIdState?.email && loginUserEmail && deleteIdState.email === loginUserEmail){
        localStorage.removeItem(SHUK_AUTH_PORTAL_TOKEN)
        Notify("error", "This user is deleted. Login again")
        navigate("/login");
        // console.log("Login User Deleted login again with differnet user.")
      }
    })
    .catch((err)=>{
      console.log(err)
    })
    setDeleteModalState(false);
  }

  return (
    <div className="staff-table-container">
      <Row>
        <Col lg={12}>
          <Card style={{minHeight: "35vh"}}>
            <CardTitle className="fs-2 dot-after ms-3">Staff</CardTitle>
            <CardBody>
              <div className="d-flex justify-content-end flex-row align-items-center" style={{width: "100%"}}>
                <div className="me-3">
                  <DataFilter
                    options={[
                      { id: "", name: "All" },
                      { id: "Admin", name: "Admin" },
                      { id: "Review Committee", name: "Review Committee" },
                      { id: "Board Member", name: "Board Member" },
                    ]}
                    value={filters.role}
                    label="User Type"
                    onChange={e => {
                      setFilters(prev => ({
                        ...prev,
                        role: e.target.value,
                      }))
                    }}
                  />
                </div>
                <div>
                  <ItemsPerPage
                    value={itemsPerPage}
                    handleItemsPerPage={handleItemsPerPage}
                  />
                </div>
              </div>

              <div className="" style={{minHeight: "35vh", maxHeight: "40vh", overflowX: "auto", overflowY: "auto", position: "relative" }}>
                {loading ? (
                  <Loader />
                ) : (
                  <Table className="table mb-0 table-striped table-bordered">
                    <thead>
                      <tr>
                        {/* <th className="text-nowrap">#</th> */}
                        <th className="text-nowrap">First Name</th>
                        <th className="text-nowrap">Last Name</th>
                        <th className="text-nowrap">Email</th>
                        <th className="text-nowrap">Phone</th>
                        <th className="text-nowrap">User Type</th>
                        <th className="text-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {staffMembersData.map((staff, index) => (
                        <tr key={staff?.id}>
                          {/* <td className="text-nowrap">{staff.id}</td> */}
                          <td className="text-nowrap">{staff?.firstName}</td>
                          <td className="text-nowrap">{staff?.lastName}</td>
                          <td className="text-nowrap">{staff?.email}</td>
                          <td className="text-nowrap">{staff?.phoneNumber}</td>
                          <td className="text-nowrap">
                            {staff?.roles?.length > 0 ? staff?.roles[0] : ""}
                          </td>
                          <td className="d-flex">
                            <MdModeEdit
                              style={{ cursor: "pointer" }}
                              className="me-2 text-primary"
                              onClick={() => handleUpdate(staff)}
                              size={18}
                            />
                            <MdDelete
                              style={{ cursor: "pointer" }}
                              className="text-danger"
                              onClick={() => {
                                setDeleteIdState(staff)
                                setDeleteModalState(true)
                              }}
                              size={18}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
            </CardBody>
            <CardFooter>
              <div className="d-flex justify-content-center justify-content-md-end justify-content-lg-end align-items-center flex-wrap">
                <div style={{ marginLeft: "4px" }}>
                  <PaginationComponent
                    totalPages={totalPages}
                    currentPage={currentPage}
                    paginate={paginate}
                  />
                </div>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={deleteModalState} centered>
        <Card className="bg-white">
          <CardBody className="mt-2">
            <h5 style={{ fontWeight: "normal" }}>
              Are You Sure. You want to Delete ?
            </h5>
          </CardBody>
          <CardFooter>
            <div className="mt-4 float-end">
              <button
                className="btn btn-danger me-2"
                onClick={() => handleDelete()}
              >
                Yes
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setDeleteModalState(false)}
              >
                No
              </button>
            </div>
          </CardFooter>
        </Card>
      </Modal>
    </div>
  )
}
