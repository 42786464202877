import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Notify from "components/Common/notify"
import {
  applicationsCreateAPI,
  applicationsDeleteAPI,
  allApplicationsFetchAPI,
  applicationsUpdateAPI,
  applicationFetchAPI,
  applicationsQuestions,
  applicationAssignStaffs,
  applicationAssignStaffsUpdate,
  updateDiscripancyStatus,
  applicationSendCommentAPI,
  applicationGetCommentsAPI,
  sendRecommendationAPI,
  getMiscellaneousDocsAPI,
  deleteMiscellaneousDocAPI,
} from "api/applications/applicationsApiHelper"

export const AllApplicationFetch = createAsyncThunk(
  "Applications/AllApplicationFetch",
  async data => {
    try {
      const response = await allApplicationsFetchAPI(data)
      const _response = {
        data: response?.data,
        status: response?.status,
      }
      return _response
    } catch (error) {
      Notify("error", "Failed to fetch applications.")
      throw Error("Failed to fetch applications")
    }
  },
)

export const ApplicationFetch = createAsyncThunk(
  "Applications/ApplicationFetch",
  async id => {
    try {
      const response = await applicationFetchAPI(id)
      const _response = {
        data: response?.data,
        status: response?.status,
      }
      return _response
    } catch (error) {
      Notify("error", "Failed to fetch application data")
      throw Error("Failed to fetch application data")
    }
  },
)

export const ApplicationCreate = createAsyncThunk(
  "Applications/ApplicationCreate",
  async data => {
    try {
      const response = await applicationsCreateAPI(data)
      // console.log("create application response", response)
      if (response?.status === 200 || response?.status === 201) {
        Notify("success", "application added successfully.")
        return response.data
      } else {
        Notify("error", "Failed to create applications")
        throw Error("Failed to create applications")
      }
    } catch (error) {
      Notify("error", "Failed to create applications")
      throw Error("Failed to create applications")
    }
  },
)

export const ApplicationUpdate = createAsyncThunk(
  "Applications/ApplicationUpdate",
  async ({ id: id, data: data }) => {
    try {
      const response = await applicationsUpdateAPI({ id: id, data: data })
      if (response?.status === 200) {
        Notify("success", "application updated.")
        return response.data
      } else {
        Notify("error", "Failed to update application")
        throw Error("Failed to update application")
      }
    } catch (error) {
      Notify("error", "Failed to update application")
      throw Error("Failed to update application")
    }
  },
)

export const ApplicationDelete = createAsyncThunk(
  "Applications/ApplicationDelete",
  async id => {
    try {
      const response = await applicationsDeleteAPI(id)
      if (response?.status === 200) {
        Notify("success", "application deleted.")
        return response.data
      } else {
        Notify("error", "Failed to delete application")
        throw Error("Failed to delete application")
      }
    } catch (error) {
      Notify("error", "Failed to delete application")
      throw Error("Failed to delete application")
    }
  },
)

export const ApplicationQuestions = createAsyncThunk(
  "Applications/GetQuestions",
  async () => {
    try {
      const response = await applicationsQuestions()
      if (response?.status === 200) {
        let _response = {
          data: response?.data,
          status: response?.status
        }
        return _response
      } else {
        throw Error("Failed to get application questions")
      }
    } catch (error) {
      // Notify("error", "Failed to get application questions")
      throw Error("Failed to get application questions")
    }
  },
)

export const AssignStaff = createAsyncThunk(
  "Applications/ApplicationAssignStaff",
  async (data) => {
    try {
      const response = await applicationAssignStaffs(data)
      if (response?.status === 200) {
        let _response = {
          data: response?.data,
          status: response?.status,
          postData: data,
        }
        let message = response?.data?.message || "Staff Assign"
        Notify("success", message)
        return _response
      } else {
        Notify("error", "Failed to Assigned Staff")
        throw Error("Failed to Assigned Staff")
      }
    } catch (error) {
      Notify("error", "Failed to Assigned Staff")
      throw Error("Failed to Assigned Staff")
    }
  },
)

export const UpdateAssignStaff = createAsyncThunk(
  "Applications/UpdateAssignStaff",
  async (data) => {
    try {
      const response = await applicationAssignStaffsUpdate(data)
      if (response?.status === 200) {
        let _response = {
          data: response?.data,
          status: response?.status,
          postData: data,
        }
        let message = response?.data?.message || "Assign Staff Updated"
        Notify("success", message)
        return _response
      } else {
        Notify("error", "Failed to Assigned Staff")
        throw Error("Failed to Assigned Staff")
      }
    } catch (error) {
      Notify("error", "Failed to Assigned Staff")
      throw Error("Failed to Assigned Staff")
    }
  },
)

export const DiscripantApplication = createAsyncThunk(
  "Applications/DiscripantApplication",
  async (data) => {
    try {
      const response = await updateDiscripancyStatus(data)
      if (response?.status === 200) {
        let _response = {
          data: response?.data,
          status: response?.status,
          postData: data,
        }
        let message = response?.data?.message || "Status updated"
        Notify("success", message)
        return _response
      } else {
        Notify("error", "Failed to update Status")
        throw Error("Failed to update Status")
      }
    } catch (error) {
      Notify("error", "Failed to update Status")
      throw Error("Failed to update Status")
    }
  },
)

export const SendApplicationComment = createAsyncThunk(
  "Applications/SendApplicationComment",
  async (data) => {
    try {
      const response = await applicationSendCommentAPI(data)
      if (response?.status === 200) {
        let _response = {
          data: response?.data,
          status: response?.status,
          postData: data,
        }
        return _response
      } else {
        Notify("error", "Failed to send comment")
        throw Error("Failed to send comment")
      }
    } catch (error) {
      Notify("error", "Failed to send comment")
      throw Error("Failed to send comment")
    }
  },
)

export const GetApplicationComments = createAsyncThunk(
  "Applications/GetApplicationComments",
  async (staff_id) => {
    try {
      const response = await applicationGetCommentsAPI(staff_id)
      if (response?.status === 200) {
        let _response = {
          data: response?.data,
          status: response?.status,
          // postData: data,
        }
        return _response
      } else {
        Notify("error", "Failed to fetch comment")
        throw Error("Failed to fetch comment")
      }
    } catch (error) {
      Notify("error", "Failed to fetch comment")
      throw Error("Failed to fetch comment")
    }
  },
)

export const SendRecommendation = createAsyncThunk(
  "Recommendation/SendRecommendation",
  async (data) => {
    try {
      const response = await sendRecommendationAPI(data)
      const _response = {
        data: response?.data,
        status: response?.status,
        postData: data,
      }
      Notify("success", "Status Updated.")
      return _response
    } catch (error) {
      Notify("error", "Failed to update status")
      throw Error("Failed to update status")
    }
  },
)

export const GetMiscellaneousDocs = createAsyncThunk(
  "Applications/GetMiscellaneousDocs",
  async (s_id) => {
    try {
      const response = await getMiscellaneousDocsAPI(s_id)
      const _response = {
        data: response?.data,
        status: response?.status,
      }
      return _response
    } catch (error) {
      // Notify("error", "Failed to get miscellaneous documents")
      throw Error("Failed to get miscellaneous documents")
    }
  },
)

export const DeleteMiscellaneousDoc = createAsyncThunk(
  "Applications/DeleteMiscellaneousDoc",
  async (data) => {
    try {
      const response = await deleteMiscellaneousDocAPI(data)
      const _response = {
        data: response?.data,
        status: response?.status,
        postData: data
      }
      Notify("success", "Document deleted.")
      return _response
    } catch (error) {
      Notify("error", "Failed to delete document")
      throw Error("Failed to delete document")
    }
  },
)

const initialState = {
  applicationsData: [],
  applicationsQuestions: [],
  singleApplicationDetail: {},
  currentApplicationSelected: {},
  applicationComments: [],
  miscellaneousDocs: [],
  totalPages: 0,
  loading: false,
  error: null,
}

export const ApplicationSlice = createSlice({
  name: "ApplicationSlice",
  initialState,
  reducers: {
    SelectingCurrentApplication: (state, action) => {
      // console.log("action payload for selecting application", action.payload)
      return { ...state, currentApplicationSelected: action.payload }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(AllApplicationFetch.pending, state => {
        state.loading = true
        state.singleApplicationDetail = {}
        state.applicationsData = []
        state.error = null
      })
      .addCase(AllApplicationFetch.fulfilled, (state, action) => {
        console.log("all application fetch payload", action.payload?.data?.data?.items)
        state.loading = false
        state.totalPages = action.payload?.data?.data?.totalPages
        state.applicationsData = action.payload?.data?.data?.items
      })
      .addCase(AllApplicationFetch.rejected, (state, action) => {
        console.log("error all application fetch", action.error)
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(ApplicationFetch.pending, state => {
        state.loading = true
        state.error = null
        state.singleApplicationDetail = {};
      })
      .addCase(ApplicationFetch.fulfilled, (state, action) => {
        console.log("application fetch payload", action.payload)
        state.loading = false
        state.singleApplicationDetail = action.payload?.data?.studentInfo
      })
      .addCase(ApplicationFetch.rejected, (state, action) => {
        console.log("error application fetch", action.error)
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(ApplicationCreate.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(ApplicationCreate.fulfilled, (state, action) => {
        console.log("application create payload", action.payload)
        state.loading = false
        state.applicationsData = [
          ...state.applicationsData,
          action.payload.data,
        ]
      })
      .addCase(ApplicationCreate.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(ApplicationUpdate.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(ApplicationUpdate.fulfilled, (state, action) => {
        state.loading = false
        console.log("application update payload", action.payload)
        if (action.payload) {
          state.applicationsData = state.applicationsData.map(st =>
            st.id === action.payload?.data?.id ? action.payload?.data : st,
          )
        }
      })
      .addCase(ApplicationUpdate.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(ApplicationDelete.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(ApplicationDelete.fulfilled, (state, action) => {
        state.loading = false
        console.log("application delete payload", action.payload)
        state.applicationsData = state.applicationsData.filter(
          i => i?.id !== action.payload?.data,
        )
      })
      .addCase(ApplicationDelete.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(ApplicationQuestions.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(ApplicationQuestions.fulfilled, (state, action) => {
        state.loading = false
        // console.log("application questions payload", action.payload?.data?.items)
        state.applicationsQuestions = action.payload?.data?.items
      })
      .addCase(ApplicationQuestions.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(AssignStaff.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(AssignStaff.fulfilled, (state, action) => {
        state.loading = false;
        // console.log("staff assigning payload", action.payload);
        
        const application = state.applicationsData.find(
          app => app?.id === action.payload?.postData?.studentId,
        );
      
        if (application) {
          application.staffAssigned = action.payload?.postData?.staffIds;
        }      
      })
      .addCase(AssignStaff.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(UpdateAssignStaff.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(UpdateAssignStaff.fulfilled, (state, action) => {
        state.loading = false;
        console.log("staff assigning update payload", action.payload);
        
        const application = state.applicationsData.find(
          app => app?.id === action.payload?.postData?.studentId,
        );
      
        if (application) {
          application.staffAssigned = action.payload?.postData?.staffIds;
        }      
      })
      .addCase(UpdateAssignStaff.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(DiscripantApplication.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(DiscripantApplication.fulfilled, (state, action) => {
        state.loading = false;
        // console.log("discripant status update payload", action.payload);
        
        const application = state.applicationsData.find(
          app => app?.id === action.payload?.postData?.stdId,
        );
        // console.log("finded application", application)
        if (application) {
          application.isDiscrepent = action.payload?.postData?.discrepantStatus;
        }      
      })
      .addCase(DiscripantApplication.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(SendApplicationComment.pending, state => {
        // state.loading = true
        state.error = null
      })
      .addCase(SendApplicationComment.fulfilled, (state, action) => {
        console.log("Send comment payload", action.payload);
        let data = {
          authorName: "",
          comments: action.payload?.postData?.commentData?.comments,
          id: action.payload?.postData?.StaffId,
          time: action.payload?.postData?.commentData?.time,
        }
        state.applicationComments.push(data); 
        // state.loading = false;
      })
      .addCase(SendApplicationComment.rejected, (state, action) => {
        // state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(GetApplicationComments.pending, state => {
        // state.loading = true
        state.error = null
        state.applicationComments = []
      })
      .addCase(GetApplicationComments.fulfilled, (state, action) => {
        // state.loading = false;
        console.log("Get comments payload", action.payload); 
        let commentArray = action.payload?.data?.items?.comments
        if(commentArray && commentArray?.length > 0){
          state.applicationComments = action.payload?.data?.items?.comments   
        }
        else{
          state.applicationComments = []
        }
      })
      .addCase(GetApplicationComments.rejected, (state, action) => {
        // state.loading = false
        state.error = action.error.message
      })
    builder
      .addCase(SendRecommendation.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(SendRecommendation.fulfilled, (state, action) => {
        state.loading = false;
        console.log("Recommendation in slice", action.payload);
        // const { studentId, status } = action.payload?.postData || {};
        state.applicationsData = state.applicationsData?.map(application => {
          if (application?.id === action.payload?.postData?.studentId) {
            return { ...application, applicationStatus: action.payload?.postData?.status };
          }
          return application;
        });
        if (state?.currentApplicationSelected && state?.currentApplicationSelected?.applicationStatus && action.payload?.postData?.status) {
          state.currentApplicationSelected.applicationStatus = action.payload?.postData?.status;
        }
      })
      .addCase(SendRecommendation.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
        console.log("slice error recommendation", action.error.message)
      })
    builder
      .addCase(GetMiscellaneousDocs.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(GetMiscellaneousDocs.fulfilled, (state, action) => {
        state.loading = false;
        console.log("Misc in slice", action.payload?.data?.data); 
        state.miscellaneousDocs = action.payload?.data?.data;
      })
      .addCase(GetMiscellaneousDocs.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
        // console.log("slice error misc", action.error.message)
      })
    builder
      .addCase(DeleteMiscellaneousDoc.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(DeleteMiscellaneousDoc.fulfilled, (state, action) => {
        state.loading = false;
        // console.log("Misc del in slice", action.payload); 
        // state.miscellaneousDocs = [];
        state.miscellaneousDocs = state.miscellaneousDocs.map(doc => ({
          ...doc,
          documentUrls: doc.documentUrls.filter(docUrl => docUrl?.id !== action.payload?.postData?.documentId),
        }));
        console.log("state.miscellaneousDocs updates", state?.miscellaneousDocs)
        // state.applicationsData = state.miscellaneousDocs.map(m_doc => {
        //   if (application.id === studentId) {
        //     return { ...application, applicationStatus: status };
        //   }
        //   return application;
        // });
      })
      .addCase(DeleteMiscellaneousDoc.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
        console.log("slice error misc", action.error.message)
      })
  },
})

export const { SelectingCurrentApplication } = ApplicationSlice.actions

export const allApplications = state => state.allApplications

export default ApplicationSlice.reducer
