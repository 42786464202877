import { axiosInstance, instanceAuth } from "api/apiConfig"

export const chatUsersFetchAPI = async staff_id => {
  try {
    console.log("Chat users for staff: ", staff_id)
    const response = await instanceAuth.get(`Chat/GetStaffChats?staffId=${staff_id}`)
    console.log("response thunk", response)
    return response
  } catch (error) {
    console.log("error fetching chat users...", error)
    throw error
  }
}

export const chatMessagesFetchAPI = async data => {
  try {
    console.log("chatroom message for:", data)
    const response = await instanceAuth.get("Chat/GetMessages", {
      params: {
        ...data,
      },
    })
    return response
  } catch (error) {
    console.log("error fetching chat users...", error)
    throw error
  }
}

export const chatMessagesFetchAllStaffAPI = async data => {
  try {
    console.log("chatroom all staff message for:", data)
    const response = await instanceAuth.get("Chat/GetStudentChats", {
      params: {
        ...data,
      },
    })
    return response
  } catch (error) {
    console.log("error fetching all staff messages...", error)
    throw error
  }
}

export const sendMessageAPI = async data => {
  console.log("Sending message:", data)
  try {
    const response = await instanceAuth.post("Chat/SendMessage", data)
    return response
  } catch (error) {
    console.log("error sending message...", error)
    throw error
  }
}

export const updateChatReadAPI = async data => {
  console.log("updating message read:", data)
  try {
    const response = await instanceAuth.put("Chat/UpdateChatIsRead", null, {
      params: {
        ...data,
      },
    })
    return response
  } catch (error) {
    console.error("Error updating message read...", error)
    throw error
  }
}
