import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import { allChatsData, ChatMessagesFetch, ChatUsersFetch, UpdateChattingPerson } from "redux/slice/chats/chatSlice"
import { useSelector } from "react-redux"
import { withTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import Loader from "components/Common/loader"
import { SHUK_AUTH_PORTAL_TOKEN } from "redux/enums"
import { parseJwt } from "utilities/parse-jwt"

const NotificationDropdown = props => {
  const dispatch = useDispatch();
  const { chatsUsersList, loadingChats, staffId } = useSelector(allChatsData)
  const [notificationsItems, setNotificationsItems] = useState([])
  const [menu, setMenu] = useState(false)

  useEffect(()=>{
    const getChatsUsers = async () => {
      const token = localStorage.getItem(SHUK_AUTH_PORTAL_TOKEN)
      let StaffId = await parseJwt(token) 
      console.log("StaffId for chats users", StaffId?.["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"])
      // let StaffId = "96fe0338-75b4-4551-b5ae-08dcf4dbc176"
      setTimeout(()=>{
        dispatch(ChatUsersFetch(StaffId?.["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"]))
      }, 1000)
    }
    getChatsUsers();
  }, [])

  useEffect(()=>{
    if(chatsUsersList?.length > 0){
      let _notification = chatsUsersList?.filter((item)=> item?.isRead === false)
      setNotificationsItems(_notification)
      console.log("new notifications @@@@@@@@@@@@@@@", _notification)
    }
  }, [chatsUsersList])

  const handleOpenChat = (open_chat_user) => {
    console.log("chat person received to open chat", open_chat_user)
    if(open_chat_user){
      dispatch(UpdateChattingPerson(open_chat_user))
      let data = {
        studentId: open_chat_user.studentId,
        staffId: staffId,
      }
      dispatch(ChatMessagesFetch(data))
    }
    setMenu(!menu)
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block ms-1"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="ti-bell"></i>
          {
            notificationsItems && notificationsItems?.length > 0 ? 
            <span className="badge text-bg-danger rounded-pill">{notificationsItems?.length}</span> : null
          }
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          {
            !loadingChats ? 
            <>
              <div className="p-3">
                <Row className="align-items-center">
                  <Col>
                    {/* <h5 className="m-0"> {props.t("Notifications")} (258) </h5> */}
                    <h5 className="m-0"> {props.t("Notifications")}</h5>
                  </Col>
                </Row>
              </div>

              <SimpleBar style={{ height: "230px" }}>
                {
                  notificationsItems?.length > 0 && (notificationsItems.map((chat, index)=>(
                    <Link onClick={()=>handleOpenChat(chat)} to="/chat" key={chat?.studentId} className="text-reset notification-item">
                      <div className="d-flex">
                        <div className="d-flex align-items-center">
                          <div className="avatar-xs">
                              <span className="avatar-title border-warning rounded-circle ">
                                <i className="mdi mdi-message"></i>
                              </span>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <h6 className="mt-0 mb-1">{chat?.studentName}</h6>
                        </div>
                      </div>
                    </Link>
                  )))
                }
              </SimpleBar>
              <div className="p-2 border-top d-grid">
                <Link
                  className="btn btn-sm btn-link font-size-14 btn-block text-center"
                  onClick={()=>setMenu(false)}
                  to="/chat"
                >
                  <i className="mdi mdi-arrow-right-circle me-1"></i>
                  {" "}
                  {props.t("View all")}{" "}
                </Link>
              </div>
            </> : <div className="py-5"><Loader /></div>
          }
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any
}