import { SHUK_AUTH_PORTAL_TOKEN } from "redux/enums"

export const parseJwt = async token => {
  try {
    const base64Url = token.split(".")[1]
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join(""),
    )

    return JSON.parse(jsonPayload)
  } catch (e) {
    return null
  }
}

export const getUserTokenID = async () => {
  try {
    const token = localStorage.getItem(SHUK_AUTH_PORTAL_TOKEN)
    if(token){
      let parsedDetails = await parseJwt(token)
      let userId =
        parsedDetails?.[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
        ]
      return userId
    }
    return ""
  } catch (e) {
    return ""
  }
}

export const getUserTokenEmail = async () => {
  try {
    const token = localStorage.getItem(SHUK_AUTH_PORTAL_TOKEN)
    if(token){
      let parsedDetails = await parseJwt(token)
      let userEmail =
        parsedDetails?.[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
        ]
      return userEmail
    }
    return ""
  } catch (e) {
    return ""
  }
}

export const getUserTokenRole = async () => {
  try {
    const token = localStorage.getItem(SHUK_AUTH_PORTAL_TOKEN)
    if(token){
      let parsedDetails = await parseJwt(token)
      let userEmail =
        parsedDetails?.[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ]
      return userEmail
    }
    return ""
  } catch (e) {
    return ""
  }
}
