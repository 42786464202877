import {
  InputField,
  PasswordInputField,
  PhoneInputField,
  SelectField,
  SelectFieldForId,
  TextareaField,
} from "components/Common/input-fields"
import Notify from "components/Common/notify"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { ReimbursementCreate } from "redux/slice/donation/reimbursement"

import { allStudents, StudentsFetch } from "redux/slice/students/studentsSlice"

export default function ReimbursementForm(props) {
  const dispatch = useDispatch()

  const { studentsData, loading, totalPages } = useSelector(allStudents)
  const [rates, setRates] = useState({});


  let passwordRegex = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/
  let mobileRegex = /^\+\d{12}$/
  let emailRegex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/
  const [error, setError] = useState("")
  const [createData, setCreateData] = useState({
    studentId:"",
    currency: "",
    disburseAmount: "",
    disburseCanadianAmount: "",
    disburseDate: "",
  
  })
  const API_URL = "https://v6.exchangerate-api.com/v6/9ace38fba2368024037f229f/latest/CAD";
 

  useEffect(() => {
    console.log("for update as a prop ", props?.donarMasterData)
    if (
      props?.donarMasterData &&
      Object.keys(props?.donarMasterData).length !== 0
    ) {
      setCreateData(props?.donarMasterData)
    }
    dispatch(StudentsFetch())


      // Fetch the latest exchange rates
   const fetchRates = async () => {
    try {
      const response = await fetch(API_URL);
      if (!response.ok) throw new Error("Failed to fetch rates");
      const data = await response.json();
      console.log(data.conversion_rates.CAD,"................");
      
      setRates(data.conversion_rates); // Store rates
     //  setLoading(false);
    } catch (err) {
     //  setError(err.message);
     //  setLoading(false);
    }
  };

  fetchRates();
  }, [])

  console.log("allStudents", studentsData);

  function currencyConverter(amount){
    const converted = parseFloat(amount) / rates[createData.currency]
    console.log("converted", converted.toFixed(2));
    
    setCreateData(prevState => ({
      ...prevState,
       disburseCanadianAmount: converted.toFixed(2),
    }))
   }
  

  const handleChangeInfo = (fieldName, value) => {
    console.log(fieldName, "change to", value)

    if(fieldName === "disburseAmount") currencyConverter(value)
    setCreateData(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  }

  const handleSubmit = () => {
    console.log("createdata", createData)
    if (
      !createData.studentId ||
      !createData.currency ||
      !createData.disburseAmount ||
      !createData.disburseCanadianAmount ||
      !createData.disburseDate
    ) {
      Notify("error", "Fill al the fields required fields.")
    } else {
        dispatch(ReimbursementCreate(createData))
        .then(result => {
          console.log("resultfromReimbursement", result)
          if (result?.payload?.items?.status === 200) {
            props.toggleTab()
          } 
        })
        .catch(err => {
          console.log("create error", err)
        })
    }
  }

  return (
    <div className="card p-2">
      <div className="card-title fs-3 mt-2 mx-4">Add Disbursement</div>
      <div className="card-body">
        <div className="row">
          <SelectFieldForId
            label="Student"
            placeholder="Enter first name"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            selectOptions={studentsData}
            disabled={props?.viewState}
            type="text"
            value={createData.studentId}
            onChange={e => handleChangeInfo("studentId", e.target.value)}
          />
          <SelectField
            label="Currency"
            placeholder="Enter middle name"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={false}
            selectOptions={Object.keys(rates)}

            disabled={props?.viewState}
            type="text"
            value={createData.currency}
            onChange={e => handleChangeInfo("currency", e.target.value)}
          />
          <InputField
            label="Disbursement Amount"
            placeholder="Disbursement Amount"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={false}
            disabled={props?.viewState}
            type="number"
            value={createData.disburseAmount}
            onChange={e => handleChangeInfo("disburseAmount", e.target.value)}
          />
          <InputField
            label="Equivalent Canadian Amount"
            placeholder="Canadian Amount"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={false}
            disabled={props?.viewState}
            type="number"
            value={createData.disburseCanadianAmount}
            onChange={e => handleChangeInfo("disburseCanadianAmount", e.target.value)}
          />
           <InputField
            label="Disburse Date"
            placeholder="Enter primary email"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="date"
            value={createData.disburseDate}
            onChange={e => {
              handleChangeInfo("disburseDate", e.target.value)
              // handleChangeInfo("userName", e.target.value)
            }}
          />
          {/* <InputField
            label="Primary Email"
            placeholder="Enter primary email"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="email"
            value={createData.primaryEmail}
            onChange={e => {
              handleChangeInfo("primaryEmail", e.target.value)
              handleChangeInfo("userName", e.target.value)
            }}
          />
          <InputField
            label="Secondary Email"
            placeholder="Enter secondary email"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="email"
            value={createData.secondaryEmail}
            onChange={e => {
              handleChangeInfo("secondaryEmail", e.target.value)
              handleChangeInfo("userName", e.target.value)
            }}
          />
          <PhoneInputField
            label="Primary Phone Number"
            placeholder="Enter primary phone"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            value={createData.primaryPhone}
            onChange={value => handleChangeInfo("primaryPhone", value)}
          />
          <PhoneInputField
            label="Secondary Phone Number"
            placeholder="Enter secondary phone"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            value={createData.secondaryPhone}
            onChange={value => handleChangeInfo("secondaryPhone", value)}
          />
          <InputField
            label="Board Member"
            placeholder="Enter board member"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.boardMember}
            onChange={e => handleChangeInfo("boardMember", e.target.value)}
          />
          <InputField
            label="Country"
            placeholder="Enter country"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.country}
            onChange={e => handleChangeInfo("country", e.target.value)}
          />
          <InputField
            label="State"
            placeholder="Enter state"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.state}
            onChange={e => handleChangeInfo("state", e.target.value)}
          />
          <InputField
            label="City"
            placeholder="Enter city"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.city}
            onChange={e => handleChangeInfo("city", e.target.value)}
          />
          <InputField
            label="Zip Code"
            placeholder="Enter zip code"
            className="col-sm-12 col-md-6 col-lg-4 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.zipCode}
            onChange={e => handleChangeInfo("zipCode", e.target.value)}
          />
          <InputField
            label="Address"
            placeholder="Enter address"
            className="col-sm-12 col-md-6 col-lg-8 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.address}
            onChange={e => handleChangeInfo("address", e.target.value)}
          />
          <TextareaField
            label="Note/Comments"
            placeholder="Type note or comments"
            className="col-sm-12 col-md-12 col-lg-12 my-2"
            compulsory={true}
            disabled={props?.viewState}
            type="text"
            value={createData.comments}
            onChange={e => handleChangeInfo("comments", e.target.value)}
          /> */}
        </div>
        {props?.viewState ? null : (
          <div className="d-flex justify-content-end mt-4">
            <button
              className="btn btn-danger me-2"
              onClick={() => props.toggleTab()}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={handleSubmit}>
              {props?.donarMasterData &&
              Object.keys(props?.donarMasterData).length !== 0
                ? "Update"
                : "Add"}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
