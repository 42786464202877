import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Modal,
  Row,
  Table,
} from "reactstrap"
import { MdDelete, MdModeEdit, MdViewAgenda } from "react-icons/md"
import { useEffect, useState } from "react"
import { DataFilter, ItemsPerPage } from "components/Common/filters"
import { useSelector, useDispatch } from "react-redux"
import PaginationComponent from "components/Common/pagination"
import Loader from "components/Common/loader"
import {
  allDonations_,
  DonationDelete,
  DonationFetch,
} from "redux/slice/donation/donations_"



export default function DonationsTable(props) {
  const dispatch = useDispatch()
  const { donationsData, loading, totalPages } = useSelector(allDonations_)
  const [itemsPerPage, setItemsPerPage] = useState(5)
  const [currentPage, setCurrentPage] = useState(1)
  const [deleteIdState, setDeleteIdState] = useState(null)
  const [deleteModalState, setDeleteModalState] = useState(false)
  const [filters, setFilters] = useState({
    role: "",
  })

  console.log("donationsData", donationsData);
  

  const paginate = pageNumber => {
    console.log("paginate call with page number: ", pageNumber)
    setCurrentPage(pageNumber)
    let data = {
      pageNumber: pageNumber,
      pageSize: itemsPerPage,
      // Role: filters.role ? filters.role : null
    }
    dispatch(DonationFetch(data))
  }

  useEffect(() => {
    let data = {
      pageNumber: 1,
      pageSize: itemsPerPage,
      //   Role: filters.role ? filters.role : null,
    }
    dispatch(DonationFetch(data))
  }, [itemsPerPage, filters])

  const handleItemsPerPage = perPage => {
    console.log("perPage", perPage)
    setItemsPerPage(Number(perPage))
  }

  const handleView = received => {
    console.log("update", received)
    props.forView(received)
  }

  const handleUpdate = received => {
    console.log("update", received)
    props.forUpdate(received)
  }

  const handleDelete = () => {
    console.log("deleteIdState", deleteIdState)
    // dispatch(DonationDelete(deleteIdState))
    setDeleteModalState(false)
  }

  return (
    <div>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody style={{ minHeight: "400px" }}>
              <CardTitle className="fs-2 dot-after">
                Donations
              </CardTitle>
              <div
                className="d-flex justify-content-end flex-row align-items-center"
                style={{ width: "100%" }}
              >
                <div>
                  <ItemsPerPage
                    value={itemsPerPage}
                    handleItemsPerPage={handleItemsPerPage}
                  />
                </div>
              </div>

              <div className="table-responsive">
                {loading ? (
                  <Loader />
                ) : (
                  <Table className="table mb-0 table-striped table-bordered">
                    <thead>
                      <tr>
                        {/* <th className="text-nowrap">ID</th> */}
                        <th className="text-nowrap">Name</th>
                        <th className="text-nowrap">Currency</th>
                        <th className="text-nowrap">Donation Amount</th>
                        <th className="text-nowrap">Canadian Amount</th>
                        <th className="text-nowrap">Donation Date</th>
                        <th className="text-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {donationsData.map((donation_m, index) => (
                        <tr key={donation_m?.id}>
                          {/* <td className="text-nowrap">{donation_m.id}</td> */}
                          <td className="text-nowrap">
                            {donation_m?.firstName + " " + donation_m?.middleName + " " + donation_m?.lastName} 
                          </td>
                          <td className="text-nowrap">
                            {donation_m?.currency}
                          </td>
                          <td className="text-nowrap">
                            {donation_m?.donationAmount}
                          </td>
                          <td className="text-nowrap">{donation_m?.donationCanadianAmount}</td>
                          <td className="text-nowrap">{donation_m?.donationDate}</td>
                          {/* <td className="d-flex">
                            <MdViewAgenda
                              style={{ cursor: "pointer" }}
                              className="me-2 text-info"
                              onClick={() => handleView(donation_m)}
                              size={18}
                            />
                            <MdModeEdit
                              style={{ cursor: "pointer" }}
                              className="me-2 text-primary"
                              onClick={() => handleUpdate(donation_m)}
                              size={18}
                            />
                            <MdDelete
                              style={{ cursor: "pointer" }}
                              className="me-2 text-danger"
                              onClick={() => {
                                setDeleteIdState(donation_m.id)
                                setDeleteModalState(true)
                              }}
                              size={18}
                            />
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
            </CardBody>
            <CardFooter>
              <div className="d-flex justify-content-center justify-content-md-end justify-content-lg-end align-items-center flex-wrap">
                <div style={{ marginLeft: "4px" }}>
                  <PaginationComponent
                    totalPages={totalPages}
                    currentPage={currentPage}
                    paginate={paginate}
                  />
                </div>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={deleteModalState} centered>
        <Card className="bg-white">
          <CardBody className="mt-2">
            <h5 style={{ fontWeight: "normal" }}>
              Are You Sure. You want to Delete ?
            </h5>
          </CardBody>
          <CardFooter>
            <div className="mt-4 float-end">
              <button
                className="btn btn-danger me-2"
                onClick={() => handleDelete()}
              >
                Yes
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setDeleteModalState(false)}
              >
                No
              </button>
            </div>
          </CardFooter>
        </Card>
      </Modal>
    </div>
  )
}
